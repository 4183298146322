<!-- @format -->
<script>
	import { isPresent, isBlank } from 'utils/tools';
	import InputDadataAddress from '~/svelte/components/ui/input_dadata_address.svelte';
	import InputDadataEmail from '~/svelte/components/ui/input_dadata_email.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import InputDaDataParty from '~/svelte/components/ui/input_dadata_party.svelte';
	import { isObject, toArray } from 'utils/tools';
	import searchBy from '~/svelte/components/ui/dadata/search_by.js';
	import InputErrorsString from '~/svelte/components/ui/input_errors_string.svelte';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';

	export let contractor;
	export let errors;
	export let individualFields;
	export let counterparty;
	export let nameFieldFilled;
	export let disabled = false;
	export let counterparties = [];

	let organization = {
		short_name: null,
		full_name: null,
		inn: null,
		ogrn: null,
		legal_address_full: null,
		leader_full_name: null,
		phone: null,
		email: null,
		mailing_address_full: null,
		errors: {},
	};

	const injectCountry = (address, country) => {
		const parts = address.split(',');
		const zipcode = parts.shift();
		parts.unshift(zipcode, ' ' + country);
		return parts.join(',');
	};

	const partySelected = ({ detail: data }) => {
		if (isObject(data)) {
			organization.full_name = (data.name && data.name.full_with_opf) || '';
			organization.inn = data.inn;
			organization.kpp = data.kpp;
			organization.ogrn = data.ogrn;
			organization.leader_full_name = (data.management && data.management.name) || '';
			organization.legal_address_full = injectCountry(data.address.unrestricted_value, data.address.data.country);
			organization.phone = toArray(data.phones)[0];
			organization.email = toArray(data.emails)[0];

			if (counterparty.roles.includes('bank')) {
				searchBy('bank', organization.inn).then(result => {
					if (result.length) {
						const r = result[0];
						counterparty.bank_bik = r.data.bic;
						counterparty.bank_rkc = r.data.rkc;
						counterparty.bank_cor_account = r.data.correspondent_account;
						counterparty.bank_name = r.value;
					}
				});
			}
		} else {
			organization.short_name = data;
		}
	};

	$: individualFields = { organization };
	$: mailingAddressFull = contractor && contractor.organization.mailing_address_full;
	$: legalAddressFull = contractor && contractor.organization.legal_address_full;
	$: mailingAddressCheckValue = mailingAddressFull && legalAddressFull && mailingAddressFull === legalAddressFull;
	$: mailingAddressChecked = mailingAddressCheckValue;
	$: nameFieldFilled = organization.short_name;
	$: duplicatedCounterparty = counterparties?.find(c => {
		if (isBlank(organization.short_name)) {
			return false;
		}
		return c.name.toLowerCase() === organization.short_name.toLowerCase() && c.id !== counterparty.id;
	});

	$: hasDuplicatedCounterparty = isPresent(duplicatedCounterparty);

	$: if (contractor) organization = contractor.organization;

	$: if (mailingAddressChecked && organization) organization.mailing_address_full = organization.legal_address_full;

	$: formFields = {
		shortName: {
			required: true,
			attribute: 'short_name',
			model: 'organization',
			label: 'Сокращенное наименование',
			placeholder: 'Введите наименование, ИНН, ОГРН или адрес',
			errors: errors && errors.short_name,
			disabled,
		},
		fullName: {
			attribute: 'full_name',
			model: 'organization',
			label: 'Полное наименование',
			placeholder: 'Общество с ограниченной ответственностью “Компания”',
			disabled,
		},
		inn: {
			attribute: 'inn',
			model: 'organization',
			label: 'ИНН',
			maskOptions: { mask: '000000000000' },
			placeholder: '123456789012',
			errors: errors && errors.inn,
			disabled,
		},
		kpp: {
			attribute: 'kpp',
			model: 'organization',
			label: 'КПП',
			maskOptions: { mask: '000000000' },
			placeholder: '123456789',
			errors: errors && errors.kpp,
			disabled,
		},
		ogrn: {
			attribute: 'ogrn',
			model: 'organization',
			label: 'ОГРН',
			maskOptions: { mask: '0000000000000' },
			placeholder: '1234567890123',
			errors: errors && errors.ogrn,
			disabled,
		},
		leaderFullName: {
			attribute: 'leader_full_name',
			model: 'organization',
			label: 'ФИО руководителя',
			placeholder: 'Фамилия И.О.',
			disabled,
		},
		phone: {
			attribute: 'phone',
			model: 'organization',
			label: 'Телефон',
			placeholder: '+7 (___) ___-__-__',
			maskOptions: { mask: '+7 (000) 000-00-00' },
			modify: phone => phone && phone.replace(/\+7|\D/g, ''),
			errors: errors && errors.phone,
			disabled,
		},
		email: {
			attribute: 'email',
			model: 'organization',
			label: 'Электронная почта',
			placeholder: 'mail@mail.ru',
			errors: errors && errors.email,
			disabled,
		},
		website: {
			attribute: 'website',
			model: 'organization',
			label: 'Сайт',
			placeholder: 'site.ru',
			errors: errors && errors.website,
			disabled,
		},
		legalAddressFull: {
			attribute: 'legal_address_full',
			model: 'organization',
			label: 'Юридический адрес',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1',
			disabled,
		},
		mailingAddressFull: {
			disabled: mailingAddressChecked,
			attribute: 'mailing_address_full',
			model: 'organization',
			label: 'Почтовый адрес',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1',
			disabled,
		},
		mailingAddressCheck: {
			text: 'тот же, что и юридический адрес',
			disabled,
		},
	};
</script>

<div>
	<InputDaDataParty {...formFields.shortName} bind:value={organization.short_name} on:change={partySelected} />
	{#if hasDuplicatedCounterparty}
		<div class="form-group row has-error" style="margin-top: -1rem;">
			<div class="col-sm-4 col-lg-3 col-xl-4 col-form-label"></div>
			<div class="col-sm-8 col-lg-9 col-xl-8">
				<InputErrorsString errors={'Контрагент с таким наименованием уже существует'} />
			</div>
		</div>
	{/if}
	<Input {...formFields.fullName} bind:value={organization.full_name} />
	<Input {...formFields.inn} bind:value={organization.inn} />
	<Input {...formFields.kpp} bind:value={organization.kpp} />
	<Input {...formFields.ogrn} bind:value={organization.ogrn} />
	<InputDadataAddress {...formFields.legalAddressFull} bind:value={organization.legal_address_full} />
	<InputDadataAddress {...formFields.mailingAddressFull} bind:value={organization.mailing_address_full} />
	<Checkbox {...formFields.mailingAddressCheck} bind:checked={mailingAddressChecked} />
	<Input {...formFields.leaderFullName} bind:value={organization.leader_full_name} />
	<Input {...formFields.phone} bind:value={organization.phone} />
	<InputDadataEmail {...formFields.email} bind:value={organization.email} />
	{#if counterparty.roles.includes('cryptoex')}
		<Input {...formFields.website} bind:value={organization.website} />
	{/if}
</div>
