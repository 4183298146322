/** @format */

import { get } from 'svelte/store';
import { format } from 'date-fns';

export const isObject = val => typeof val === 'object' && val !== null && !isArray(val);

export const isPrimitive = val => val === undefined || val === null || !(isObject(val) || isArray(val));

export const isPresent = val => {
	if (val === null) {
		return false;
	} else if (typeof val == 'boolean') {
		return val;
	} else if (typeof val === 'string' || val instanceof String) {
		return val.trim().length > 0;
	} else if (Array.isArray(val)) {
		return val.length > 0;
	} else if (val instanceof Date) {
		return true;
	} else if (val instanceof File) {
		return true;
	} else if (isObject(val)) {
		return Object.keys(val).length > 0;
	} else {
		return val == 0 || !!val;
	}
};

export const isBlank = val => !isPresent(val);

export const isPresence = val => (isPresent(val) ? val : false);

export const isArray = val => Array.isArray(val);

export const isInteger = val => isPresent(val) && Number.isInteger(val * 1);

export const isFloat = val => Number(val) === val && val % 1 !== 0;

export const isNumber = val => isFloat(val) || isInteger(val);

export const isString = val => typeof val === 'string';

export const isDate = val => (isString(val) ? Date.parse(val) > 0 : val instanceof Date);

export const toInteger = val => (isPresent(val) ? val * 1 : 0);

export const toFloat = val => (isPresent(val) ? (isString(val) ? parseFloat(val.replace(/\s/g, '').replace(/,/, '.')) : parseFloat(val)) : 0.0);

export const toString = val => (isPresent(val) ? val.toString() : '');

export const toArray = val => (isArray(val) ? val : []);

export const ifBlank = (val, def_val) => isPresence(val) || def_val;

let timer;

export const debounce = (method, delay = 100) => {
	clearTimeout(timer);
	timer = setTimeout(method, delay);
};

export const pushState = (path, params = {}) => {
	const url = path + (Object.keys(params).length ? '?' + new URLSearchParams(params) : '');
	window.history.pushState(params, '', url);
};

export const keyPressed = e => {
	const is_symbol = e.code && e.code.indexOf('Key') >= 0,
		is_digit = (e.which >= 48 && e.which <= 57) || (e.keyCode >= 48 && e.keyCode <= 57),
		is_escape = e.which == 27 || e.keyCode == 27,
		is_space = e.which == 32 || e.keyCode == 32,
		is_del = e.which == 46 || e.keyCode == 46,
		is_backspace = e.which == 8 || e.keyCode == 8,
		is_enter = e.which == 13 || e.keyCode == 13,
		is_up = e.which == 38 || e.keyCode == 38,
		is_down = e.which == 40 || e.keyCode == 40,
		is_tab = e.which == 9 || e.keyCode == 9,
		is_symbol_or_del_or_backspace = is_symbol || is_del || is_backspace;

	return { is_symbol, is_digit, is_del, is_backspace, is_enter, is_space, is_escape, is_up, is_down, is_tab, is_symbol_or_del_or_backspace };
};

export const sleep = milliseconds => {
	const date = Date.now();
	let currentDate = null;
	do {
		currentDate = Date.now();
	} while (currentDate - date < milliseconds);
};

// Гауссово округление, также известное как банковское округлением,
// заключается в том, что округление для этого случая происходит к ближайшему чётному.
// Этот метод округления работает без статистической погрешности. Лучшее решение было предложено Tim Down: https://github.com/timdown
export const gaussRound = (num, decimalPlaces) => {
	let d = decimalPlaces || 0,
		m = Math.pow(10, d),
		n = +(d ? num * m : num).toFixed(8),
		i = Math.floor(n),
		f = n - i,
		e = 1e-8,
		r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
	return d ? r / m : r;
};
// Возвращает массив чисел начиная с startAt
export const range = (size, startAt = 0) => [...Array(size).keys()].map(i => i + startAt);
// Создает JSON-url
export const buildJsonUrl = page => {
	if (isBlank(page)) return;

	let path = page.path;
	const hasQuery = isPresent(page.query);
	const hasSlash = path.charAt(path.length - 1) === '/';

	// remove slash
	path = hasSlash && path !== '/' ? path.slice(0, -1) : path;
	path = path + '.json';

	return hasQuery ? path + '?' + new URLSearchParams(page.query) : path;
};
// Добавляет .json к основному урлу
export const convertToJsonUrl = url => {
	if (isBlank(url)) return;

	const parts = url.split('?');
	const hasSlash = parts[0].charAt(parts[0].length - 1) === '/';

	// remove slash
	parts[0] = hasSlash && parts[0] !== '/' ? parts[0].slice(0, -1) : parts[0];
	parts[0] = parts[0] + '.json';

	return parts.join('?');
};
// Добавляет класс DOM-элементу
export const addClass = (target, klass) => {
	let klasses = target.classList.value.split(' ');
	let index = klasses.indexOf(klass);

	if (index === -1) {
		klasses.push(klass);
		target.classList.value = klasses.join(' ');
	}
};
// Удаляет класс у DOM-элемента
export const removeClass = (target, klass) => {
	let klasses = target.classList.value.split(' ');
	let index = klasses.indexOf(klass);

	if (index > -1) {
		klasses.splice(index, 1);
		target.classList.value = klasses.join(' ');
	}
};
// Заменяет класс у DOM-элемента
export const replaceClass = (target, klass) => (target.classList.value = klass);
// Удаляет все классы у DOM-элемента
export const removeAllClasses = target => (target.classList.value = ' ');
// Все значения ключей с "null" заменяем на "" иначе в форме будет отображаться "null"
export const replaceNulls = (data, s = '') => {
	let h = isArray(data) ? [...data] : { ...data };

	Object.keys(h).forEach(key => {
		if (isPrimitive(h[key]) && isBlank(h[key])) {
			h[key] = s;
		} else if (isArray(h[key]) && isBlank(h[key])) {
			h[key] = [];
		} else if (isObject(h[key])) {
			h[key] = replaceNulls(h[key], s);
		}
	});
	return h;
};
// Случайное целое число от min до max
export const randomInteger = (min, max) => {
	// получить случайное число от (min-0.5) до (max+0.5)
	const rand = min - 0.5 + Math.random() * (max - min + 1);
	return Math.round(rand);
};
export const randomString = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
// Генератор случайных имен файлов на основе blob.type
export const randomFilename = blob_type => {
	const s = Math.random().toString(36).substr(2, 12);
	const a = blob_type.split('/');
	return s + '.' + a[a.length - 1];
};
// Ruby each_slice analog
export const each_slice = (arr, size) => {
	const res = [];
	for (let i = 0, l = arr.length; i < l; i += size) {
		res.push(arr.slice(i, i + size));
	}
	return res;
};
// export const each_slice = (arr, size, callback) => {
//   for (let i = 0, l = arr.length; i < l; i += size) {
//     callback.call(arr, arr.slice(i, i + size))
//   }
// }
export const uniqueArray = arr => arr.filter((elem, pos, arrr) => arrr.indexOf(elem) == pos);

export const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});

export const truncateString = (string, limit) => {
	if (string.length > limit) {
		return string.substring(0, limit - 3) + '...';
	} else {
		return string;
	}
};

export const formatDate = dt => (isPresent(dt) ? format(new Date(dt), 'dd.LL.yyyy') : '');
export const formatDateISO = dt => (isPresent(dt) ? format(new Date(dt), 'yyyy-LL-dd') : '');

export const formatSum = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format;
export const formatSumRub = val => formatSum(val) + ' ₽';
// Склонение числительного caseWord(15, 'день', 'дня', 'дней') => дней
export const caseWord = (number, case1, case2, case3) => {
	const suff = { 1: case1, 2: case2, 3: case2, 4: case2, 5: case3, 6: case3, 7: case3, 8: case3, 9: case3, 0: case3 };
	const num = Math.abs(number);

	if (num > 10 && num < 20) {
		return case3;
	} else {
		const arr = (num + '').split('');
		return suff[arr[arr.length - 1]];
	}
};
// Проверка попадания DOM-элемента в область видимости контейнера
export const isVisible = (element, container) => {
	const { bottom, height, top } = element.getBoundingClientRect();
	const containerRect = container.getBoundingClientRect();

	return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
};
// const isVisible2 = ele => {
//   const eleTop = ele.offsetTop;
//   const eleBottom = eleTop + ele.clientHeight;
//
//   const containerTop = ctrl_popup.scrollTop;
//   const containerBottom = containerTop + ctrl_popup.clientHeight;
//
//   // The element is fully visible in the container
//   return (eleTop >= containerTop && eleBottom <= containerBottom) ||
//       // Some part of the element is visible in the container
//       (eleTop < containerTop && containerTop < eleBottom) ||
//       (eleTop < containerBottom && containerBottom < eleBottom);
// };
export const imgOnErrorHandle = e => {
	if (!e.target.getAttribute('data-error-fired') && e.target.getAttribute('data-error')) {
		e.target.src = e.target.getAttribute('data-error');
		e.target.setAttribute('data-error-fired', true);
	}
};

// парсит ошибки для отображения в svelte-forms-lib
export const errorProcessing = (error, fields_errors_store, base_errors_store) => {
	if (isObject(error)) {
		const other_errors = [];
		const errors = get(fields_errors_store);

		Object.keys(error).forEach(key => {
			if (key in errors) {
				fields_errors_store.update(h => {
					h[key] = isArray(error[key]) ? error[key].join(', ') : error[key];
					return h;
				});
			} else if (isArray(error[key])) {
				error[key].forEach(mess => other_errors.push(key == 'base' ? mess : key + ': ' + mess));
			} else {
				other_errors.push(key == 'base' ? error[key] : key + ': ' + error[key]);
			}
		});

		base_errors_store.set(isPresent(other_errors) ? other_errors : undefined);
	} else {
		base_errors_store.set(error);
	}
};

export const daysBetweenDates = (dt1, dt2) => (new Date(dt2) - new Date(dt1)) / 86400000; // 86400000 = 1000 * 60 * 60 * 24

export const createObjectURL = blob => (window.URL && window.URL.createObjectURL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob));

export const extractFilename = response => {
	const s = (response.headers.get('content-disposition') || response.headers.get('disposition')).split("filename*=UTF-8''");
	return decodeURIComponent(s[1]);
};

export const deepEqual = (obj1, obj2) => {
	if (obj1 === obj2) return true;

	if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
		return false;
	}

	let keys1 = Object.keys(obj1);
	let keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) return false;

	for (let key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
};
