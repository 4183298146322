<!-- @format -->
<script>
	import { autosaveStatus, transmitter as transmitter0 } from 'base_stores';
	import { onMount, afterUpdate, createEventDispatcher } from 'svelte';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import { validate } from 'tools/validators';
	import { isPresent } from '~/js/utils/tools';

	// Вложенные атрибуты можно передавать через точку
	// model: 'a', attribute: 'b.c'
	// params => { 'a': { 'b': { 'c': value...
	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let required = false;
	export let disabled = false;
	export let transmitter;
	export let skipVerificationRequired = true;
	export let value;
	export let options = [];
	export let placeholder;
	export let hintData;
	export let handleBlocking = () => {};
	export let checkSuitability = () => {
		return true;
	};
	export let actionInUnsuitability = () => {};
	export let forceSave = false;
	export let blockHandleChange = () => {
		return false;
	};
	export let addOption = null;
	export let additionalParams = {};
	export let saveAutomatically = true;
	export let requiredAttributes = {};

	const dispatch = createEventDispatcher();

	let dataErrors;
	let selectNode = null;
	let searchInputNode = null;
	let wrapperBordered = null;
	let previousValue;
	let enterPressed = false;
	let confirmedCreatingNewItem = false;
	let textOfOptionBeingAdded;

	$: method = id ? fetchPut : fetchPost;
	$: if (id) {
		url = `${url}/${id}`;
	}
	$: defaultValue = options && options.filter(option => option).length === 1 ? options.find(option => +option.id) : null;

	$: attrId = `select2-search-${model}-${attribute.split('.').at(-1)}`; //-${randomString()}`
	$: valueId = value && value.id;

	$: if (valueId && options.find(option => parseInt(option.id) === parseInt(value.id)) && selectNode) {
		setSelect2();
	}

	$: convertedOptions =
		options &&
		options.map(option => {
			const countOptions = options.filter(checkedOption => checkedOption.text === option.text).length;
			//const countOptions = options.length
			//if (countOptions < 2 || !option.user_id) { return option }
			//return { ...option, text: `${option.text} (изм.)` }

			if (option.user_id) {
				const q =
					countOptions < 2
						? option //{ ...option, text: `<span>${option.text} <iconify-icon icon='fa:user' class='mt-1 float-right'/></span>` } :
						: { ...option, text: `${option.text} (изм.)` };
				return q;
			} else {
				return option;
			}
		});

	$: if (selectNode && disabled) {
		wrapperBordered = selectNode.next().first().find(`#select2-${attrId}-container`).parent();
		wrapperBordered.removeClass('has-success').removeClass('has-error');
	}

	$: if (selectNode && value && value.id) {
		wrapperBordered = selectNode.next().first().find(`#select2-${attrId}-container`).parent();
		wrapperBordered.addClass('has-success').removeClass('has-error');
		if (required) {
			wrapperBordered.removeClass('required-border');
		}
	} else if (selectNode && !(value && value.id)) {
		wrapperBordered = selectNode.next().first().find(`#select2-${attrId}-container`).parent();
		wrapperBordered.removeClass('has-error');
		if (required) {
			wrapperBordered.addClass('required-border');
		}
	}

	$: if (value !== undefined && forceSave) {
		handleChange(value.id);
	}

	$: if (defaultValue && (!valueId || defaultValue.id != valueId) && ['addressee_id', 'correspondent_id'].includes(attribute) && saveAutomatically) {
		handleChange(defaultValue.id);
	}

	$: if (!defaultValue) {
		saveAutomatically = true;
	}
	$: if (defaultValue && previousValue != defaultValue.id) {
		saveAutomatically = true;
	}

	const handleChange = newValue => {
		if (defaultValue) {
			saveAutomatically = false;
		}
		previousValue = newValue;
		$autosaveStatus = null;

		forceSave = false;
		if (newValue === 'null') {
			newValue = null;
		}

		if (!blockHandleChange(newValue)) {
			dataErrors = validate(model, attribute, newValue, skipVerificationRequired);

			if (!dataErrors) {
				let valueHash = attribute
					.split('.')
					.reverse()
					.reduce((acc, curr) => ({ [curr]: acc }), newValue);
				let params = { [model]: { ...valueHash, ...requiredAttributes }, [`${childModel}_id`]: childId, ...additionalParams };
				// let params = { [model]: { [attribute]: newValue, ...requiredAttributes }, [`${childModel}_id`]: childId, ...additionalParams }
				method(url, params)
					.then(response => {
						if (!forceSave && JSON.stringify(response) !== '{}') {
							$autosaveStatus = 'saved';
						}
						transmitter = { ...transmitter, ...response };
						$transmitter0 = { ...$transmitter0, ...response };
						previousValue = newValue;
						dispatch('update', response);
					})
					.catch(_errors => {
						$autosaveStatus = 'not_saved';
					});
				return;
			}
			$autosaveStatus = 'not_saved';
		}

		handleBlocking();
	};

	const createOption = async () => {
		value = await addOption(value.text);
		forceSave = true;
	};

	const templateResult = state => {
		const optionWithIcon = convertedOptions && convertedOptions.find(option => option.id == state.id && option.user_id);

		let displayedText = document.createElement('span');
		if (!checkSuitability(state)) {
			displayedText.classList.add('unsuitable');
		}
		if (!optionWithIcon) {
			displayedText.textContent = state.text;
			return displayedText;
		} else {
			displayedText.innerHTML = `${state.text} <iconify-icon class='mt-1 float-right' icon='bx-bxs-user'/>`;
		}

		return displayedText;
	};

	const setSelect2 = () => {
		selectNode.select2({
			placeholder,
			allowClear: false,
			disabled: disabled,
			tags: isPresent(addOption),
			templateResult: templateResult,
			createTag: tag => {
				const data = {
					id: tag.term,
					text: `${tag.term} (создать)`,
					isNew: true,
				};
				return data;
			},
			matcher: (params, data) => {
				if (!params.term || data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
					return data;
				}
				return data.id == 'new' ? data : null;
			},
		});
	};

	const confirmIfEnter = e => {
		if (e.code == 'Enter' && /\(создать\)$/.test(textOfOptionBeingAdded)) {
			enterPressed = true;
			textOfOptionBeingAdded = false;
			confirmedCreatingNewItem = confirm('Создать новую запись?');
		}
	};

	onMount(() => {
		// eslint-disable-next-line no-undef
		selectNode = jQuery(`#${attrId}`);

		setSelect2();

		selectNode.on('select2:open', () => {
			// eslint-disable-next-line no-undef
			searchInputNode = jQuery('.select2-search__field');

			if (isPresent(addOption) && searchInputNode) {
				searchInputNode.on('keydown', confirmIfEnter);
				searchInputNode.focus();
			}
		});

		selectNode.on('select2:select', event => {
			textOfOptionBeingAdded = event.params.data.text;

			if (event.params.data.isNew) {
				setTimeout(() => {
					if (!enterPressed || (enterPressed && confirmedCreatingNewItem)) {
						createOption();
					} else {
						options = options.filter(o => o.id != event.params.data.id);
						value = undefined;
						selectNode.val(null).trigger('change');
						return
					}

					enterPressed = false;
					confirmedCreatingNewItem = false;
				}, 50);

				const text = event.params.data.text.split(' ').slice(0, -1).join(' ');
				options = options.map(o => (o.id == event.params.data.id ? { id: o.id, text: text } : o));
				value = { id: event.params.data.id, text: text };
			} else {
				if (saveAutomatically) {
					handleChange(event.params.data.id);
				}
			}

			if (!checkSuitability(event.params.data)) {
				actionInUnsuitability(event.params.data);
			}
		});
	});

	afterUpdate(() => {
		selectNode.val((value && value.id) || null).trigger('change');
		selectNode.prop('disabled', disabled);
		wrapperBordered = selectNode.next().first().find(`#select2-${attrId}-container`).parent();
		if (!disabled) {
			value ? wrapperBordered.addClass('has-success').removeClass('has-error') : wrapperBordered.addClass('has-error').removeClass('has-success');
		}
		setSelect2(); // Нужно для того, чтобы обновить опции, если нужно сортировать опции по региону
	});
</script>

<div class="form-group row" class:has-error={dataErrors}>
	<label for={attrId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<div class="select-wrapper">
			<select class="form-control" id={attrId}>
				{#each convertedOptions || [] as option}
					<option value={option.id}>
						{@html option.text}
					</option>
				{/each}
			</select>
		</div>
		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
