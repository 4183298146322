<!-- @format -->
<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { fetchGet } from 'utils/fetch';
	import Header from '../components/header.svelte';
	import { showModal } from '../components/modal.svelte';
	import WorkerModal from './_modal.svelte';
	import { formatSum } from 'utils/tools';
	import TableRows from './table_rows.svelte';
	import DownloadListModal from './download_list_modal.svelte';

	const collator = new Intl.Collator();

	let procedureId = null;
	let workerId = null;
	let requiredFieldsFilled = false;

	const tableTitles = ['ФИО работника', 'Должность', 'Дата приказа об увольнении', 'Задолженность перед работником'];

	$: workers = ($transmitter && $transmitter.counterparties) || workers || [];
	$: worker = workers && workers.find(worker => worker.id === workerId);
	$: deleteIconDisabled = !workerId || (worker && !worker.can_be_destroyed);
	$: dismissed_workers = workers.filter(worker => !workerField(worker, 'dismissal_order_date'));
	$: undismissed_workers = workers.filter(worker => workerField(worker, 'dismissal_order_date'));

	const openModalForm = id => {
		workerId = id;
		showModal('form-worker-modal');
	};

	const workerField = (worker, field) => {
		return 'worker' in worker.additions ? worker.additions.worker[field] || '' : '';
	};

	const arrearsToWorker = worker => {
		return parseFloat(workerField(worker, 'arrears_before_acceptance') || 0) + parseFloat(workerField(worker, 'arrears_after_acceptance') || 0);
	};

	const getWorkers = () => {
		const params = { filters: { roles: { only: 'worker' } } };

		fetchGet('/api/private/counterparties', params).then(
			response => ($transmitter = { ...$transmitter, counterparties: response.counterparties.sort((a, b) => collator.compare(a.name, b.name)) }),
		);
	};

	onMount(() => {
		procedureId = $procedure && $procedure.id;
		getWorkers();
	});
</script>

<Header><span slot="name">Работники</span></Header>
<div class="m-b-lg mb-0">
	<button
		class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!$procedureAvailable || !procedureId}
		on:click={procedureId && $procedureAvailable && openModalForm(null)}
	>
		+ Работник
	</button>
	<button
		class="btn btn-sm btn-white m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!$procedureAvailable || !procedureId}
		on:click={procedureId && showModal('download-worker-modal')}
	>
		Загрузить список
	</button>
</div>
<div class="m-b-lg overlay p-1 footer-margin">
	<table class="table table-hover m-0 table-mobile">
		<thead class="thead-light">
			<tr>
				{#each tableTitles as title}
					<th class="text-center p-w-xs">
						{title}
					</th>
				{/each}
			</tr>
		</thead>
		{#if workers}
			<tbody>
				{#if $procedure.phase == 'bankruptcy_proceedings'}
					<tr class="text-left text-secondary subkind">
						<td colspan="5">
							<span>Работники, продолжающие свою деятельность в ходе конкурсного производства</span>
						</td>
					</tr>
					<TableRows {openModalForm} {workerField} {arrearsToWorker} bind:workers={dismissed_workers} />
					<tr class="text-left text-secondary subkind">
						<td colspan="5">
							<span>Работники, уволенные (сокращенные) в ходе конкурсного производства</span>
						</td>
					</tr>
					<TableRows {openModalForm} {workerField} {arrearsToWorker} bind:workers={undismissed_workers} />
				{:else}
					<TableRows {openModalForm} {workerField} {arrearsToWorker} bind:workers />
				{/if}
			</tbody>
		{/if}
		{#if ['bankruptcy_proceedings', 'supervision'].includes($procedure.phase)}
			<tfoot>
				<tr>
					<th class="text-left">ИТОГО:</th>
					<td colspan="2"></td>
					<th class="text-right">{(workers && formatSum(workers.reduce((sum, worker) => sum + arrearsToWorker(worker), 0.0))) || 0.0}</th>
				</tr>
			</tfoot>
		{/if}
	</table>
</div>
<WorkerModal {workerId} {deleteIconDisabled} bind:requiredFieldsFilled on:create={getWorkers} on:update={getWorkers} />
<DownloadListModal on:create={getWorkers} />

<style>
	table,
	tr {
		border-collapse: separate;
		cursor: pointer;
	}
	.subkind {
		background-color: #efefef;
	}
</style>
