<!-- @format -->
<script>
	import { createEventDispatcher, tick, afterUpdate } from 'svelte';
	import { fetchGet } from '~/js/utils/fetch_helpers.js';
	import { isPresent, isBlank, isArray, randomString } from 'utils/tools';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from 'base_stores';
	import CreditorsRequirementForm from './_form.svelte';
	import CounterpartyForm, { counterpartySubmit, counterpartyCancel, counterpartyDelete } from '~/svelte/counterparties/form.svelte';
	import PayoutModal from '~/svelte/payouts/_modal.svelte';
	import AmendementModal from '~/svelte/amendements/_modal.svelte';
	import { cardsTitle } from 'utils/formats';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let disabled = false;
	export let fromAnswer = false;
	export let approveCreditorsRequirement = id => {};
	export let answer;

	let creditorRequirement;

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};

	export const editItem = id => {
		creditorRequirement = $transmitter.creditors_requirements.find(cr => cr.id === id);
		formOptions = { item: { id }, method: 'edit' };
		showModal(modalId);
	};

	export const showItem = id => {
		creditorRequirement = $transmitter.creditors_requirements.find(cr => cr.id === id);
		formOptions = { item: { id }, method: 'show' };
		showModal(modalId);
	};

	const modalId = 'modal-' + randomString();
	const counterpartyModalId = 'counterparty-modal-' + randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;
	let disabledSubmit = false;
	let payoutModal;
	let amendementModal;
	let addingСounterparty = false;

	const requirementSubmit = () =>
		form.submitForm().then(result => {
			fromAnswer = isPresent(answer);

			if (isPresent(result)) {
				// update transmitter
				if (isArray($transmitter.creditors_requirements)) {
					$transmitter.creditors_requirements = [...$transmitter.creditors_requirements.filter(r => r.id != result.item.id), result.item]
						.filter(r => (fromAnswer ? isPresent(r.answer_id) : isBlank(r.answer_id)))
						.sort((a, b) => new Date(a.judicial_act_on_inclusion_date) - new Date(b.judicial_act_on_inclusion_date));
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);

				return result;
			}
		});

	const requirementDelete = () =>
		form.deleteItem().then(id => {
			if (isArray($transmitter.creditors_requirements)) {
				$transmitter.creditors_requirements = $transmitter.creditors_requirements.filter(row => row.id != id);
			}
			dispatch('delete', id);
			return id;
		});

	const requirementDuplicate = () => {
		const { id, payout_lines, possession_ids, receivable_ids, disabled_by_amendement, born_by_amendement, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	const createRequirementForReestr = () => {
		const { id, payout_lines, possession_ids, receivable_ids, disabled_by_amendement, born_by_amendement, ...rest } = form.getItem();
		formOptions = {
			item: { ...rest, counterparty_id: answer.counterparty_id, answer_id: null, possession_ids, receivable_ids },
			duplicateMode: true,
			method: 'new',
		};

		fromAnswer = false;

		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	const counterpartyNew = () => {
		addingСounterparty = true;
		showModal(counterpartyModalId);
	};

	const counterpartyCreated = async ({ detail: id }) => {
		const response = await fetchGet('/api/private/counterparties', { filters: { roles: { only: 'creditor' } } });
		$transmitter.counterparties = response.counterparties;
		addingСounterparty = true;
		const el = $transmitter.counterparties.find(e => e.id == id);

		if (isPresent(el)) {
			form.setData({ counterparties: [...form.getData().counterparties, { value: id, label: el.name }] });
			form.setItem({ counterparty_id: id, answer_id: null });
		}
	};

	const payoutEdit = ({ detail: id }) => payoutModal.editItem(id);

	const payoutDeleted = ({ detail: id }) => {
		const rows = form.getItem().payout_lines.filter(e => e.payout_id != id);
		form.setItem({ payout_lines: rows });
	};

	const payoutUpdated = ({ detail: result }) => {
		const rows = form.getItem().payout_lines;
		const row = rows.find(el => el.payout_id == result.item.id);
		row.sum = result.item.sum;
		row.date_at = result.item.date_at;
		form.setItem({ payout_lines: rows });
	};

	const amendementDeleted = ({ detail: id }) => {
		const d = form.getItem();
		if (d.disabled_by_amendement.id == id) {
			form.setItem({ disabled_by_amendement: {} });
		} else if (d.born_by_amendement.id == id) {
			dispatch('delete', id);
			hideModal(modalId);
		}
	};

	const showAmendementModal = ({ detail: id }) => amendementModal.showItem(id);

	$: answerParams = fromAnswer
		? {
				additionalActionButtonShow: true,
				additionalActionButtonText: creditorRequirement?.state === 'draft' ? 'Утвердить' : 'Включить в РТК',
				additionalActionButtonDisabled: formOptions.method == 'show' || (formOptions.item && isBlank(formOptions.item.id)),
				additionalActionButtonAction:
					creditorRequirement?.state === 'draft' ? () => approveCreditorsRequirement(creditorRequirement.id) : createRequirementForReestr,
			}
		: {
				additionalActionButtonShow: false,
			};

	afterUpdate(() => {
		if (addingСounterparty) {
			addingСounterparty = false;
			fromAnswer = false;
		}
	});
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || disabledSubmit || !formCompleted || disabled}
	submitButtonAction={requirementSubmit}
	cancelButtonAction={() => (fromAnswer = isPresent(answer))}
	deleteIconDisabled={disabledSubmit || formOptions.method == 'new' || disabled}
	deleteButtonAction={requirementDelete}
	deleteConfirmMessage="Вы действительно хотите удалить требование?"
	deleteTipDisabled={disabledSubmit ? 'Недоступно. Есть последующее изменение' : 'Нельзя удалить'}
	showCopyAndSaveButton={!disabledSubmit && formOptions.method == 'edit' && !disabled}
	copyAndSaveButtonAction={requirementDuplicate}
	form={true}
	showError={false}
	{index}
	{...answerParams}
	size="large"
>
	<h2 slot="header">
		{cardsTitle(
			fromAnswer ? `входящего требования${creditorRequirement?.state === 'draft' ? ' (распознанная)' : ''}` : 'требования',
			formOptions.method == 'edit',
			null,
			fromAnswer,
		)}
	</h2>
	<div slot="body">
		<CreditorsRequirementForm
			{disabled}
			{fromAnswer}
			{answer}
			{index}
			bind:this={form}
			bind:isCompleted={formCompleted}
			bind:isPreviewed={disabledSubmit}
			{formOptions}
			on:newCounterparty={counterpartyNew}
			on:editPayout={payoutEdit}
			on:showAmendement={showAmendementModal}
		/>
	</div>
</Modal>

<Modal
	modalId={counterpartyModalId}
	submitButtonAction={counterpartySubmit}
	cancelButtonAction={counterpartyCancel}
	deleteButtonAction={counterpartyDelete}
	deleteIconDisabled={true}
	deleteButtonTooltipText={'Вы действительно хотите удалить контрагента?'}
	form={true}
	index={index + 1}
	size="large"
>
	<h2 slot="header" class="counterparty-modal__header">{cardsTitle('контрагента', false)}</h2>
	<div slot="body">
		<CounterpartyForm {disabled} fixedRoles={['creditor']} on:create={counterpartyCreated} />
	</div>
</Modal>

<PayoutModal {disabled} bind:this={payoutModal} index={index + 1} on:update={payoutUpdated} on:delete={payoutDeleted} />

<AmendementModal {disabled} bind:this={amendementModal} index={index + 1} on:delete={amendementDeleted} />
