<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { fetchGet } from '~/js/utils/fetch_helpers.js';
	import { isPresent, isArray, randomString } from 'utils/tools';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from 'base_stores';
	import Form from './_form.svelte';
	import CounterpartyForm, { counterpartySubmit, counterpartyCancel, counterpartyDelete } from '~/svelte/counterparties/form.svelte';
	import { cardsTitle } from 'utils/formats';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let disabled = false;

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = id => {
		formOptions = { item: { id }, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = id => {
		formOptions = { item: { id }, method: 'show' };
		showModal(modalId);
	};

	const modalId = 'modal-' + randomString();
	const counterpartyModalId = 'modal-' + randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;
	let counterpartyForm;
	let kind;

	const formSubmit = () =>
		form.submitForm().then(result => {
			if (isPresent(result)) {
				// update transmitter
				if (isArray($transmitter.creditors_requirements)) {
					const arr = [...$transmitter.creditors_requirements].filter(row => row.id != result.item.id).concat([result.item]);
					arr.sort((a, b) => new Date(a.judicial_act_on_inclusion_date) - new Date(b.judicial_act_on_inclusion_date));
					$transmitter.creditors_requirements = [...arr];
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
				return result;
			}
		});
	const formDelete = () => {
		const d = form.getItem();
		return form.deleteItem().then(id => {
			if (isArray($transmitter.creditors_requirements)) {
				$transmitter.creditors_requirements = $transmitter.creditors_requirements.filter(row => !d.born_creditors_requirement_ids.includes(row.id));

				$transmitter.creditors_requirements.forEach(row => {
					if (d.disabled_creditors_requirement_ids.includes(row.id)) {
						row.disabled_by_amendement_id = '';
					}
				});
				$transmitter.creditors_requirements = $transmitter.creditors_requirements;
			}
			dispatch('delete', id);
			return id;
		});
	};
	const formDuplicate = () => {
		const { id, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	let cannotBeDeleted = false;
	let newCounterpartyMode;

	const counterpartyNewOld = () => {
		newCounterpartyMode = 'old';
		showModal(counterpartyModalId);
	};
	const counterpartyNewNew = () => {
		newCounterpartyMode = 'new';
		showModal(counterpartyModalId);
	};
	const counterpartyCreated = async ({ detail: id }) => {
		const response = await fetchGet('/api/private/counterparties', { filters: { roles: { only: 'creditor' } } });
		$transmitter.counterparties = response.counterparties;
		const el = $transmitter.counterparties.find(e => e.id == id);

		if (isPresent(el)) {
			form.setData({ counterparties: [...form.getData().counterparties, { value: id, label: el.name }] });
			if (newCounterpartyMode == 'old') {
				form.setItem({ counterparty_id: id });
			} else if (newCounterpartyMode == 'new') {
				form.setItem({ new_counterparty_id: id });
			}
		}
	};

	let hint;

	const setHint = kind => {
		if (kind == 'substitution_all') {
			hint = `
		  <div><strong>Что произойдет:</strong></div>\
		  <div>- останется старый кредитор со старым номером</div>\
		  <div>- добавится новый кредитор с новым номером</div>\
		  <div>- останется старое требование старого кредитора со старым номером</div>\
		  <div>- старое требование пометится как исключенное</div>\
		  <div>- добавится новое требование нового кредитора с новым номером</div>\
		  <div>- сумма нового требования будет равна сумме старого за минусом платежей по нему</div>`;
		} else if (kind == 'substitution_partly') {
			hint = `
			<div><strong>Что произойдет:</strong></div>\
			<div>- останется старый кредитор со старым номером</div>\
			<div>- добавится новый кредитор с новым номером</div>\
			<div>- останется старое требование старого кредитора со старым номером</div>\
			<div>- старое требование пометится как исключенное</div>\
			<div>- добавится новое требование нового кредитора с новым номером</div>\
			<div>- добавится новое требование старого кредитора с новым номером</div>\
			<div>- сумма нового требования старого кредитора будет равна сумме старого</div>\
			<div>за минусом платежей по нему и суммы нового требования нового кредитора</div>`;
		} else if (kind == 'exclusion') {
			hint = `
			<div><strong>Что произойдет:</strong></div>\
			<div>- останется кредитор со старым номером</div>\
			<div>- останется требование кредитора со старым номером</div>\
			<div>- требование пометится как исключенное</div>`;
		} else if (kind == 'reduction') {
			hint = `
			<div><strong>Что произойдет:</strong></div>\
			<div>- останется кредитор со старым номером<div>\
			<div>- останется старое требование со старым номером<div>\
			<div>- старое требование пометится как исключенное<div>\
			<div>- добавится новое требование с новым номером<div>\
			<div>- сумма нового требования будет равна сумме старого</div>\
			<div>за минусом платежей по нему и исключаемой суммы<div>`;
		} else if (kind == 'other') {
			hint = `
			<div><strong>Что произойдет:</strong></div>\
			<div>- останется кредитор со старым номером</div>\
			<div>- добавится кредитор с новым номером (если он не был ранее в Реестровых/За реестром)</div>\
			<div>- останется старое требование кредитора со старым номером</div>\
			<div>- старое требование пометится как исключенное</div>\
			<div>- добавится новое требование с новым номером и измененными свойствами</div>\
			<div>- сумма нового требования будет равна сумме старого за минусом платежей по нему</div>`;
		} else {
			hint = undefined;
		}
	};

	$: setHint(kind);
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !formCompleted || disabled}
	submitButtonAction={formSubmit}
	deleteIconDisabled={cannotBeDeleted || formOptions.method == 'new' || disabled}
	deleteButtonAction={formDelete}
	deleteConfirmMessage="Вы действительно хотите удалить изменение?"
	deleteTipDisabled={cannotBeDeleted ? 'Недоступно. Есть последующее изменение' : 'Нельзя удалить'}
	showCopyAndSaveButton={false}
	showError={false}
	form={true}
	{hint}
	{index}
	size="large"
>
	<h2 slot="header">Карточка изменения требования</h2>
	<div slot="body">
		<Form
			{disabled}
			bind:this={form}
			bind:isCompleted={formCompleted}
			bind:cannotBeDeleted
			bind:kind
			{formOptions}
			on:newOldCounterparty={counterpartyNewOld}
			on:newNewCounterparty={counterpartyNewNew}
		/>
	</div>
</Modal>

<Modal
	modalId={counterpartyModalId}
	submitButtonAction={counterpartySubmit}
	cancelButtonAction={counterpartyCancel}
	deleteButtonAction={counterpartyDelete}
	deleteIconDisabled={true}
	deleteButtonTooltipText={'Вы действительно хотите удалить контрагента?'}
	form={true}
	index={index + 1}
	size="large"
>
	<h2 slot="header" class="counterparty-modal__header">{cardsTitle('контрагента', newCounterpartyMode != 'new')}</h2>
	<div slot="body">
		<CounterpartyForm {disabled} fixedRoles={['creditor']} on:create={counterpartyCreated} />
	</div>
</Modal>
