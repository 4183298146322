<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import { cardsTitle } from 'utils/formats';
	import CounterpartyForm from '~/svelte/counterparties/_form.svelte';

	export let deleteIconDisabled;
	export let requiredFieldsFilled;
	export let workerId;
	export let fixedTypes = ['person'];
	export let fixedRoles = ['worker'];
	export let index;
	export let type = 'worker';
	export let selectedWorkerId;
	export let modalId = 'form-worker-modal';
	export let disabled = false;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	{modalId}
	{index}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	deleteIconDisabled={deleteIconDisabled || disabled}
	submitButtonDisabled={!requiredFieldsFilled || disabled}
	deleteButtonTooltipText={'Вы действительно хотите удалить работника?'}
	form={true}
	size="large"
>
	<h2 slot="header" class="worker-modal__header">{cardsTitle('работника', workerId)}</h2>
	<div slot="body">
		<CounterpartyForm
			{disabled}
			{type}
			{fixedTypes}
			{fixedRoles}
			bind:selectedCounterpartyId={selectedWorkerId}
			bind:counterpartyId={workerId}
			bind:handleSubmit
			bind:handleDelete
			bind:handleCancel
			bind:requiredFieldsFilled
			on:create
			on:update
		/>
	</div>
</Modal>
