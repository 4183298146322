<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { fetchGet } from '~/js/utils/fetch_helpers.js';
	import { isPresent, isArray, randomString } from 'utils/tools';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from 'base_stores';
	import RunningCostForm from './_form.svelte';
	import CounterpartyForm, { counterpartySubmit, counterpartyCancel, counterpartyDelete } from '~/svelte/counterparties/form.svelte';
	import PayoutModal from '~/svelte/payouts/_modal.svelte';
	import { cardsTitle } from 'utils/formats';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let disabled = false;

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = id => {
		formOptions = { item: { id }, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = id => {
		formOptions = { item: { id }, method: 'show' };
		showModal(modalId);
	};

	const modalId = 'modal-' + randomString();
	const counterpartyModalId = 'counterparty-modal-' + randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;
	let payoutModal;

	const runningCostSubmit = () =>
		form.submitForm().then(result => {
			if (isPresent(result)) {
				// update transmitter
				if (isArray($transmitter.running_costs)) {
					const arr = [...$transmitter.running_costs].filter(row => row.id != result.item.id).concat([result.item]);
					arr.sort((a, b) => new Date(a.date_at) - new Date(b.date_at));
					$transmitter.running_costs = [...arr];
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
				return result;
			}
		});
	const runningCostDelete = () =>
		form.deleteItem().then(id => {
			if (isArray($transmitter.running_costs)) {
				$transmitter.running_costs = $transmitter.running_costs.filter(row => row.id != id);
			}
			dispatch('delete', id);
			return id;
		});
	const runningCostDuplicate = () => {
		const { id, payout_lines, possession_ids, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	const counterpartyNew = () => showModal(counterpartyModalId);
	const counterpartyCreated = async ({ detail: id }) => {
		const response = await fetchGet('/api/private/counterparties', { filters: { roles: { only: 'current_creditor' } } });
		$transmitter.counterparties = response.counterparties;
		const el = $transmitter.counterparties.find(e => e.id == id);

		if (isPresent(el)) {
			form.setData({ counterparties: [...form.getData().counterparties, { value: id, label: el.name }] });
			form.setItem({ counterparty_id: id });
		}
	};

	const payoutEdit = ({ detail: id }) => payoutModal.editItem(id);
	const payoutDeleted = ({ detail: id }) => {
		const rows = form.getItem().payout_lines.filter(e => e.payout_id != id);
		form.setItem({ payout_lines: rows });
	};
	const payoutUpdated = ({ detail: result }) => {
		const rows = form.getItem().payout_lines;
		const row = rows.find(el => el.payout_id == result.item.id);
		row.sum = result.item.sum;
		row.date_at = result.item.date_at;
		form.setItem({ payout_lines: rows });
	};
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !formCompleted || disabled}
	submitButtonAction={runningCostSubmit}
	deleteIconDisabled={formOptions.method == 'new' || disabled}
	deleteButtonAction={runningCostDelete}
	deleteConfirmMessage="Вы действительно хотите удалить текущий расход?"
	showCopyAndSaveButton={formOptions.method == 'edit' && !disabled}
	copyAndSaveButtonAction={runningCostDuplicate}
	form={true}
	showError={false}
	{index}
	size="large"
>
	<h2 slot="header">{cardsTitle('текущего расхода', formOptions.method == 'edit')}</h2>
	<div slot="body">
		<RunningCostForm
			{disabled}
			bind:this={form}
			bind:isCompleted={formCompleted}
			{formOptions}
			on:editPayout={payoutEdit}
			on:newCounterparty={counterpartyNew}
		/>
	</div>
</Modal>

<Modal
	modalId={counterpartyModalId}
	submitButtonAction={counterpartySubmit}
	cancelButtonAction={counterpartyCancel}
	deleteButtonAction={counterpartyDelete}
	deleteIconDisabled={true}
	deleteButtonTooltipText={'Вы действительно хотите удалить контрагента?'}
	form={true}
	index={index + 1}
	size="large"
>
	<h2 slot="header">{cardsTitle('контрагента', false)}</h2>
	<div slot="body">
		<CounterpartyForm {disabled} fixedRoles={['current_creditor']} on:create={counterpartyCreated} />
	</div>
</Modal>

<PayoutModal bind:this={payoutModal} index={index + 1} on:update={payoutUpdated} on:delete={payoutDeleted} />
