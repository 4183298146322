<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch_helpers';
	import { procedure, transmitter } from 'base_stores';
	import { isBlank, toArray, formatSum, truncateString } from 'utils/tools';
	import { formatDate } from 'utils/date';
	import Header from '~/svelte/components/header.svelte';
	import PayoutModal from '~/svelte/payouts/_modal.svelte';
	import ReceiptModal from '~/svelte/receipts/_modal.svelte';
	import CreditorsRequirementModal from '~/svelte/creditors_requirements/_modal.svelte';
	import RunningCostModal from '~/svelte/running_costs/_modal.svelte';

	let rows = [];
	let subkindOptions = [];
	let receiptModal;
	let payoutModal;
	let requirementModal;
	let runningCostModal;
	let mounted = false;

	$: rows = [...toArray($transmitter.receipts), ...toArray($transmitter.payouts)].sort(e => e.date_at);

	const handleEditRunningCostItem = id => runningCostModal.editItem(id);
	const handleEditRtkItem = id => requirementModal.editItem(id);

	const handleNewPayoutItem = () => payoutModal.newItem();
	const handleEditPayoutItem = id => payoutModal.editItem(id);

	const handleNewReceiptItem = () => receiptModal.newItem();
	const handleEditReceiptItem = id => receiptModal.editItem(id);

	const handleEditItem = row => {
		if (row.type == 'receipt') {
			if (row.kind == 'between_accounts') {
				handleEditPayoutItem(row.payout_id);
			} else {
				handleEditReceiptItem(row.id);
			}
		} else {
			handleEditPayoutItem(row.id);
		}
	};

	onMount(async () => {
		if ($procedure && $procedure.id) {
			await fetchGet('/api/private/payouts', { procedure_id: $procedure.id }).then(result => {
				$transmitter.payouts = result.rows;
				$transmitter.receipts = result.receipts;
				subkindOptions = result.possession_subkind_options;
			});
			mounted = true;
		}
	});
</script>

<!-- TODO: жуткая кривизна построения страницы, разобраться с кнопками и таблицами -->
<Header><span slot="name">Поступления, платежи</span></Header>
<div class="dashboard flex-slot m-b-sm">
	<ul class="nav navbar-top-links tools-bar flex-shrink-slot flex-nowrap">
		<li>
			<button class="btn btn-sm btn-primary m-b-sm m-r-sm ml-2 ml-sm-0" disabled={isBlank($procedure)} on:click={handleNewReceiptItem}> + Поступление </button>
		</li>
		<li>
			<button class="btn btn-sm btn-danger m-b-sm m-r-sm" disabled={isBlank($procedure)} on:click={handleNewPayoutItem}> + Платеж </button>
		</li>
	</ul>
</div>
<div class="main-table footer-margin">
	<table class="table table-hover table-mobile border-collapse_separate">
		<thead class="thead-light">
			<tr>
				<th class="text-center p-w-xs">Дата операции</th>
				<th class="text-center p-w-xs">Номер документа</th>
				<th class="text-center p-w-xs">Сумма, руб.</th>
				<th class="text-center p-w-xs">Очередь</th>
				<th class="text-center p-w-xs">Назначение платежа</th>
				<th class="text-center p-w-xs">Контрагент</th>
				<th class="text-center p-w-xs">Связанные расходы/требования</th>
			</tr>
		</thead>
		<tbody class="text-center">
			{#each rows as row (row.uniq_id)}
				<tr class="cursor_pointer" on:click={() => handleEditItem(row)}>
					<td class="text-center">{formatDate(row.date_at, 'DD.MM.YYYY')}</td>
					<td class="text-right">{row.num || ''}</td>
					<td class="text-right" class:text-green={row.type == 'receipt'} class:text-danger={row.type == 'payout'}>
						{formatSum(row.sum)}
					</td>
					<td class="text-center">
						{#if row.type == 'payout'}
							{#if row.kind == 'running_costs' || row.kind == 'rtk'}
								{#if row.behind_registry}
									За реестром
								{:else}
									{(row.repayment_order == 'first'
										? 1
										: row.repayment_order == 'second'
											? 2
											: row.repayment_order == 'third'
												? 3
												: row.repayment_order == 'fourth'
													? 4
													: row.repayment_order == 'fifth'
														? 5
														: '') +
										' (' +
										(row.kind == 'running_costs' ? 'р' : 'РТК') +
										')'}
								{/if}
							{:else}
								-
							{/if}
						{:else}
							-
						{/if}
					</td>
					<td class="text-left">{row.note || ''}</td>
					<td class="text-left">{row.counterparty_name || ''}</td>
					<td class="text-left">
						{#if row.type == 'payout' && row.kind != 'between_accounts'}
							<div class="badge-container">
								{#each row.payout_lines as ln}
									<div
										role="button"
										tabindex="0"
										on:click|stopPropagation={() =>
											ln.payoutable_type == 'RunningCost' ? handleEditRunningCostItem(ln.payoutable_id) : handleEditRtkItem(ln.payoutable_id)}
										on:keypress|stopPropagation
									>
										{formatSum(ln.sum) + ' руб. (' + truncateString(ln.expense_type_text, 18) + ', ' + formatDate(row.date_at, 'DD.MM.YYYY') + ')'}
									</div>
								{/each}
							</div>
						{:else}
							-
						{/if}
					</td>
				</tr>
			{/each}
		</tbody>
		<tfoot>
			{#if mounted && isBlank(rows)}
				<tr><td colspan="10" class="text-center text-muted">Нет данных</td></tr>
			{/if}
			{#if !mounted}
				<tr><td class="text-center text-muted" colspan="10"><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
			{/if}
			<tr>
				<th class="text-right" colspan="2">Итого поступления:</th>
				<th class="text-right text-green">{formatSum($transmitter.receipts && $transmitter.receipts.reduce((sum, e) => sum + parseFloat(e.sum), 0))}</th>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">Итого платежи:</th>
				<th class="text-right text-danger">{formatSum($transmitter.payouts && $transmitter.payouts.reduce((sum, e) => sum + parseFloat(e.sum), 0))}</th>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">1 очередь (р)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts &&
							$transmitter.payouts.filter(e => e.kind == 'running_costs' && e.repayment_order == 'first').reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">2 очередь (р)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts &&
							$transmitter.payouts.filter(e => e.kind == 'running_costs' && e.repayment_order == 'second').reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">3 очередь (р)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts &&
							$transmitter.payouts.filter(e => e.kind == 'running_costs' && e.repayment_order == 'third').reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">4 очередь (р)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts &&
							$transmitter.payouts.filter(e => e.kind == 'running_costs' && e.repayment_order == 'fourth').reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">5 очередь (р)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts &&
							$transmitter.payouts.filter(e => e.kind == 'running_costs' && e.repayment_order == 'fifth').reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">1 очередь (РТК)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts &&
							$transmitter.payouts
								.filter(e => e.kind == 'rtk' && e.repayment_order == 'first' && !e.behind_registry)
								.reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">2 очередь (РТК)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts &&
							$transmitter.payouts
								.filter(e => e.kind == 'rtk' && e.repayment_order == 'second' && !e.behind_registry)
								.reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">3 очередь (РТК)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts &&
							$transmitter.payouts
								.filter(e => e.kind == 'rtk' && e.repayment_order == 'third' && !e.behind_registry)
								.reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
			<tr>
				<th class="text-right" colspan="2">За реестром (РТК)</th>
				<th class="text-right text-danger"
					>{formatSum(
						$transmitter.payouts && $transmitter.payouts.filter(e => e.kind == 'rtk' && e.behind_registry).reduce((sum, e) => sum + parseFloat(e.sum), 0),
					)}</th
				>
				<td colspan="4"></td>
			</tr>
		</tfoot>
	</table>
</div>

<RunningCostModal bind:this={runningCostModal} />
<ReceiptModal {subkindOptions} bind:this={receiptModal} />
<PayoutModal bind:this={payoutModal} />
<CreditorsRequirementModal bind:this={requirementModal} />

<style lang="scss">
	.dashboard {
		width: 100%;
		margin-bottom: 0;
	}
	.main-table {
		font-size: inherit;
		background-color: #ffffff;
		margin-bottom: 40px;
		table {
			th {
				font-size: inherit;
			}
		}
	}
</style>
