<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { fetchGet, fetchPost } from 'utils/fetch';
	import Specialist from 'models/Specialist';
	import { options, bankruptIsPerson, bankruptIsOrganization } from 'base_stores';
	import { isPresent, isBlank } from 'utils/tools';
	import { showModal } from '~/svelte/components/modal.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte';
	import RadioSelect from '~/svelte/components/ui/radio_select.svelte';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';

	const dispatch = createEventDispatcher();

	export let disabled = false;
	export let specialistId;
	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let submitButtonDisabled;

	let counterpartyId;
	let tempCounterparty = null;

	$: specialist = Specialist.find(specialistId) || Specialist.new();

	$: formFields = {
		kindId: {
			required: true,
			model: 'specialist',
			attribute: 'kind_id',
			placeholder: 'Выбрать вид',
			label: 'Вид специалиста',
			options: $options && $options.specialistKind,
			addOption: addSpecialistKindOption,
			disabled,
		},
		counterpartyId: {
			required: true,
			model: 'specialist',
			attribute: 'counterparty_id',
			placeholder: 'Выбрать специалиста',
			label: 'Cпециалист',
			//modify: 'showModalCounterpartyForm',
			options: $options && $options.currentCreditor,
			disabled,
		},
		name: {
			model: 'specialist',
			attribute: 'name',
			placeholder: 'ООО “Компания” или Фамилия И.О.',
			label: 'Название или ФИО специалиста',
			minHeight: 80,
			disabled,
		},
		courtDecisionDate: {
			model: 'specialist',
			attribute: 'court_decision_date',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			label: 'Дата определения суда',
			disabled,
		},
		contract: {
			model: 'specialist',
			attribute: 'contract',
			placeholder: 'Договор оценки №0',
			minHeight: 80,
			label: 'Номер договора',
			disabled,
		},
		contractDate: {
			model: 'specialist',
			attribute: 'contract_date',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			label: 'Дата договора',
			disabled,
		},
		contractCompletionDate: {
			model: 'specialist',
			attribute: 'contract_completion_date',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			label: 'Срок действия договора',
			disabled,
		},
		contractAccrualStartDate: {
			model: 'specialist',
			attribute: 'contract_accrual_start_date',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			label: 'Дата начала начислений по договору',
			disabled,
		},
		contractAccrualEndDate: {
			model: 'specialist',
			attribute: 'contract_accrual_end_date',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			label: 'Дата окончания начислений по договору',
			disabled,
		},
		accreditation: {
			model: 'specialist',
			attribute: 'accreditation',
			placeholder: 'Свидетельство №0 от 00.00.0000 г.',
			minHeight: 80,
			label: 'Сведения об аккредитации',
			disabled,
		},
		payment: {
			model: 'specialist',
			attribute: 'payment',
			placeholder: '0,00',
			type: 'number',
			label: 'Сумма, руб.',
			disabled,
		},
		paymentSource: {
			model: 'specialist',
			attribute: 'payment_source',
			placeholder: 'Выбрать источник',
			options: $options && $options.paymentSource,
			label: 'Источник оплаты',
			disabled,
		},
		paymentFrequency: {
			model: 'specialist',
			attribute: 'payment_frequency',
			options: $options && $options.paymentFrequency,
			label: 'Периодичность оплаты',
			disabled,
		},
	};

	$: submitButtonDisabled = isBlank(specialist.kind_id) || disabled;

	$: if (isBlank(specialist.contract_accrual_start_date) && specialist.payment_frequency === 'monthly') {
		specialist.contract_accrual_start_date = specialist.contract_date;
	}

	$: if (specialist.payment_frequency === 'once_only') {
		specialist.contract_accrual_start_date = null;
		specialist.contract_accrual_end_date = null;
	}

	handleSubmit = async () => {
		isPresent(specialistId) ? await Specialist.update(specialist) : await Specialist.create(specialist);
		dispatch('submit');
	};

	handleDelete = async () => {
		await Specialist.delete(specialistId);
		dispatch('delete');
	};

	handleCancel = () => (specialist = Specialist.new());

	const addSpecialistKindOption = async text => {
		const response = await fetchPost('/api/private/specialists/add_option', { text });
		const option = response.option;

		$options.specialistKind = [$options.specialistKind.shift(), option, ...$options.specialistKind];

		return option.id;
	};

	const showModalCounterpartyForm = value => {
		if (value === 'new') {
			tempCounterparty = specialist.counterparty_id;
			openCounterpartyModalForm();
		}
	};

	const openCounterpartyModalForm = id => {
		counterpartyId = id;
		showModal('form-counterparty-modal');
	};

	const getOptions = async () => {
		const response = await fetchGet('/api/private/counterparties/get_options_for_roles', { roles: ['current_creditor'] });
		$options.currentCreditor = [{ id: null, text: '' }, { id: 'new', text: 'Добавить специалиста' }, ...response.options];
	};

	const refresh = async newCounterpartyId => {
		await getOptions();
		specialist.counterparty_id = newCounterpartyId;
	};
</script>

<Select2 {...formFields.kindId} bind:value={specialist.kind_id} />
<!--Select2 {...formFields.counterpartyId} bind:value={specialist.counterparty_id}/-->
<Select2 {...formFields.counterpartyId} bind:value={specialist.counterparty_id} modify={showModalCounterpartyForm} />
<!--Input {...formFields.name} bind:value={specialist.name}/-->
{#if $bankruptIsPerson}
	<Datepicker {...formFields.courtDecisionDate} bind:value={specialist.court_decision_date} />
{/if}
<Input {...formFields.contract} bind:value={specialist.contract} />
<Datepicker {...formFields.contractDate} bind:value={specialist.contract_date} />
{#if $bankruptIsPerson}
	<Datepicker {...formFields.contractCompletionDate} bind:value={specialist.contract_completion_date} />
{/if}
<Input {...formFields.accreditation} bind:value={specialist.accreditation} />
<InputCurrency {...formFields.payment} bind:value={specialist.payment} />
<Select2 {...formFields.paymentSource} bind:value={specialist.payment_source} />
<RadioSelect {...formFields.paymentFrequency} bind:value={specialist.payment_frequency} />
{#if specialist.payment_frequency === 'monthly'}
	<Datepicker {...formFields.contractAccrualStartDate} bind:value={specialist.contract_accrual_start_date} />
	<Datepicker {...formFields.contractAccrualEndDate} bind:value={specialist.contract_accrual_end_date} />
{/if}

<CounterpartyModal {counterpartyId} fixedRoles={['current_creditor']} on:create={({ detail: newCounterpartyId }) => refresh(newCounterpartyId)} />
