<!-- @format -->
<script context="module">
	let handleSubmit;
	let handleCancel;
	let handleDelete;

	export function counterpartySubmit() {
		return handleSubmit();
	}
	export function counterpartyCancel() {
		return handleCancel();
	}
	export function counterpartyDelete() {
		return handleDelete();
	}
</script>

<script>
	import CounterpartyForm from '../counterparties/_form.svelte';

	export let counterpartyId;
	export let selectedCounterpartyId;
	export let fixedTypes = [];
	export let fixedRoles = [];
	export let modalId = 'form-counterparty-modal';
	export let requiredFieldsFilled;
	export let disabled = false;
</script>

<CounterpartyForm
	{disabled}
	{counterpartyId}
	{fixedTypes}
	{fixedRoles}
	{modalId}
	bind:selectedCounterpartyId
	bind:handleSubmit
	bind:handleDelete
	bind:handleCancel
	bind:requiredFieldsFilled
	on:create
/>
