<!-- @format -->
<script>
	import { fieldsReady, procedure, transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { dctFormAvailable, currentData } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { showModal } from '~/svelte/components/modal.svelte';
	import WorkerModal from '~/svelte/workers/_modal.svelte';

	const collator = new Intl.Collator();

	export let fieldId;

	let previousCounterparties;
	let procedureId = null;
	let employeeId = null;
	let indexCollapse = false;
	let forceSave = true;
	let requiredFieldsFilled = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: loadEmployees();
	$: employees = ($transmitter && $transmitter.employees) || employees || [];
	$: employee = employees && employees.find(employee => employee.id === employeeId);
	$: deleteIconDisabled = !employeeId || (employee && !employee.can_be_destroyed);
	$: if (previousCounterparties !== $transmitter.counterparties) {
		rewriteEmployees($transmitter.counterparties);
		previousCounterparties = $transmitter.counterparties;
	}
	$: bankruptIsIndividualEntrepreneur = $currentData && $currentData.bankruptIsIndividualEntrepreneur;

	const openModalForm = id => {
		employeeId = id;
		showModal('form-worker-modal');
	};

	const rewriteEmployees = () => {
		$transmitter.employees = $transmitter.counterparties
			.filter(c => c.roles.includes('worker'))
			.map(worker => {
				return {
					id: worker.id,
					full_name: worker.full_name,
					position: worker.additions.worker?.position,
					dismissal_date: worker.additions.worker?.dismissal_order_date,
				};
			})
			.sort((a, b) => collator.compare(a.name, b.name));
	};

	const loadEmployees = async () => {
		try {
			const response = await fetchGet('/api/private/counterparties');

			$transmitter = { ...$transmitter, counterparties: response.counterparties };
		} catch (error) {
			console.error(error);
		} finally {
			forceSave = false;
		}
	};
</script>

{#if bankruptIsIndividualEntrepreneur}
	<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
		<h4 class="employees col-sm-12">
			<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
			<span class="header">{`Работники (${employees.length})`}</span>
		</h4>
	</div>
	<div id="collapse-employees" class="collapse row m-t-n" class:show={indexCollapse}>
		<div class="col-sm-12">
			<button
				class="btn btn-xs btn-warning m-b-xs"
				disabled={!$dctFormAvailable}
				on:click={procedureId && $procedureAvailable && $dctFormAvailable && openModalForm(null)}
			>
				+ Работник
			</button>
			<div class="p-1">
				{#if employees.length}
					<ol class="employees-list">
						{#each employees as employee}
							<li>
								<div class="float-left simulated-link" role="button" tabindex="0" on:click={openModalForm(employee.id)} on:keypress|stopPropagation>
									{employee.full_name}
								</div>
							</li>
						{/each}
					</ol>
				{/if}
			</div>
		</div>
	</div>
	<WorkerModal
		{deleteIconDisabled}
		bind:workerId={employeeId}
		bind:requiredFieldsFilled
		disabled={!$dctFormAvailable}
		on:create={loadEmployees}
		on:update={loadEmployees}
	/>
{/if}

<style>
	.employees-list {
		display: grid;
		padding-left: 15px;
		list-style-type: circle;
	}
	.employees-list > li > div,
	.employees > .header {
		cursor: pointer;
	}
</style>
