<!-- @format -->
<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { createEventDispatcher } from 'svelte';
	import { fetchGet, fetchPatch } from '~/js/utils/fetch_helpers.js';
	import { isPresent, randomString, uniqueArray } from '~/js/utils/tools.js';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import WorkerForm, { workerSubmit, workerCancel, workerDelete } from '~/svelte/workers/form.svelte';
	import Case from 'case';
	import { currentData } from '~/svelte/dcts/stores/stores.js';
	import { cardsTitle } from 'utils/formats';

	const dispatch = createEventDispatcher();

	const counterpartyModalId = randomString();

	export let index = 0;
	export let storage = [];

	export const newItem = (options = {}) => {
		const { workers_qty_known, workers_qty, workers_info_known } = options.meeting;

		id = options.meeting.id;

		metaData = {};
		rows = [];
		kinds = [];
		errors = [];
		allIds = [];
		workers = [];
		selected = [];
		selectedAll = false;
		linkDisabled = false;

		fetchGet('/api/private/meetings/' + id + '/get_notification_kinds').then(result => {
			rows = result.rows;
			metaData = result.meta_data;
			allIds = rows.map(e => e.id);
			kinds = rows.filter(e => e.type == 'meta');
			workers = rows.filter(e => e.type == 'worker');
			selected = rows.filter(e => ['fedresource', 'newspaper', 'worker'].includes(e.kind)).map(e => e.id);

			if (workers_qty_known == 'yes' && +workers_qty <= 100 && workers_info_known == 'yes') {
				// совпадает с дефолтным поведением
			} else if (workers_qty_known == 'no' || (workers_qty_known == 'yes' && +workers_qty > 100) || workers_info_known == 'no') {
				workers = [];
				linkDisabled = true;
			}

			showModal(modalId);
		});
	};

	let id;
	let metaData = {};
	let rows = [];
	let kinds = [];
	let errors = [];
	let allIds = [];
	let workers = [];
	let selected = [];
	let selectedAll = false;
	let linkDisabled = false;

	let selectedAllWorkers = false;

	const modalId = randomString();

	const toggleAll = e => (selected = e.target.checked ? [...allIds] : []);
	const toggleAllWorkers = e =>
		(selected = e.target.checked ? uniqueArray([...selected, ...workers.map(e => e.id)]) : selected.filter(e => !e.startsWith('worker')));

	const counterpartyNew = () => showModal(counterpartyModalId);
	const counterpartyCreated = async ({ detail: id }) => {
		const response = await fetchGet('/api/private/counterparties', { filters: { roles: { only: 'worker' } } });
		$transmitter.counterparties = response.counterparties;
		const el = $transmitter.counterparties.find(e => e.id == id);

		if (isPresent(el)) {
			const data = { id: 'worker:' + el.id, name: el.name, type: 'worker', kind: 'worker' };
			rows = [...rows, data];
			workers = [...workers, data];
			allIds = [...allIds, data.id];
			selected = [...selected, data.id];
		}
	};

	const formSubmit = () => {
		if (isPresent(selected)) {
			const arr = rows.filter(e => selected.includes(e.id));

			return fetchPatch('/api/private/meetings/' + id, {
				meeting: {
					notifications_attributes: arr.map(e => {
						let substitutions = {};
						let form_fields = (metaData[e.kind] && metaData[e.kind].form_fields) || [];

						for (let key of form_fields) {
							substitutions[Case.snake(key)] = $currentData[key];
						}
						for (let person of ['bankrupt', 'arbitrManager']) {
							if (form_fields.includes(`${person}FullName`)) {
								for (let key of [`${person}Surname`, `${person}Name`, `${person}Patronymic`]) {
									substitutions[Case.snake(key)] = $currentData[key];
								}
							}
						}
						return { meta_record_id: e.id, substitutions };
					}),
				},
			})
				.then(result => {
					storage = [...result.notifications];
					dispatch('create', { storage, id: storage[storage.length - 1].id });
				})
				.catch(error => {
					errors = error;
					throw error;
				});
		}
	};

	$: selectedAll = selected.length == allIds.length;
	$: selectedAllWorkers = workers.length > 0 && workers.every(e => selected.includes(e.id));
</script>

<Modal
	{modalId}
	deleteIcon={false}
	submitButtonText="Создать"
	showCopyAndSaveButton={false}
	submitButtonDisabled={selected.length == 0}
	submitButtonAction={formSubmit}
	showError={false}
	form={true}
	{index}
	size="medium"
>
	<h2 slot="header">Создать уведомление</h2>
	<div slot="body">
		<div class="columns">
			<ul class="m-b-0 mr-auto">
				<li>
					<label>
						<input class="cursor_pointer" type="checkbox" checked={selectedAll} on:click={toggleAll} />
						<strong>Выбрать все</strong>
					</label>
				</li>
			</ul>
		</div>
		<div class="columns">
			<ul class="ml-auto">
				<li>
					<label>
						<input class="cursor_pointer" type="checkbox" disabled={workers.length == 0} checked={selectedAllWorkers} on:click={toggleAllWorkers} />
						<strong>Работники</strong>
					</label>
				</li>
				{#if workers.length == 0}
					<li style="padding-left: 18px;">
						<span>Нет работников</span>
					</li>
				{/if}
				{#each workers as row}
					<li style="padding-left: 18px;">
						<label>
							<input class="cursor_pointer" type="checkbox" bind:group={selected} value={row.id} />
							{row.name}
						</label>
					</li>
				{/each}
				<li>
					<span>
						{#if linkDisabled}
							<div class="simulated-link-disabled">Добавить работников</div>
						{:else}
							<a href on:click|preventDefault={counterpartyNew}>Добавить работников</a>
						{/if}
					</span>
				</li>
			</ul>
			<ul class="mr-auto">
				{#each kinds as row}
					<li>
						<label>
							<input class="cursor_pointer" type="checkbox" bind:group={selected} value={row.id} />
							{row.name}
						</label>
					</li>
				{/each}
			</ul>
		</div>

		<ErrorMessage message={errors} on:click={() => (errors = [])} />
	</div>
</Modal>
<Modal
	modalId={counterpartyModalId}
	submitButtonAction={workerSubmit}
	cancelButtonAction={workerCancel}
	deleteButtonAction={workerDelete}
	deleteIconDisabled={true}
	deleteButtonTooltipText={'Вы действительно хотите удалить работника?'}
	form={true}
	index={index + 1}
	size="large"
>
	<h2 slot="header" class="counterparty-modal__header">{cardsTitle('работника', false)}</h2>
	<div slot="body">
		<WorkerForm fixedTypes={['person']} fixedRoles={['worker']} on:create={counterpartyCreated} />
	</div>
</Modal>

<style lang="scss">
	.columns {
		display: flex;
		ul {
			list-style-type: none;
			padding-left: 0;
			min-width: 50%;

			input[type='checkbox'] {
				margin-right: 5px;
			}
		}
	}
</style>
