<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { isPresent, isArray, randomString } from '~/js/utils/tools.js';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from '~/js/global_stores/base.js';
	import Form from './_form.svelte';
	import CounterpartyForm, { counterpartySubmit, counterpartyCancel, counterpartyDelete } from '~/svelte/counterparties/form.svelte';
	import { cardsTitle } from 'utils/formats';
	import { fetchGet } from '~/js/utils/fetch';

	const collator = new Intl.Collator();

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let storage = [];

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = (options = {}) => {
		formOptions = { ...options, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = (options = {}) => {
		formOptions = { ...options, method: 'show', readonly: true };
		showModal(modalId);
	};

	const modalId = randomString();
	const counterpartyModalId = randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;

	const formSubmit = () =>
		form.submitForm().then(result => {
			if (isPresent(result)) {
				// update transmitter
				if (isArray(storage)) {
					const arr = [...storage].filter(row => row.id != result.item.id).concat([result.item]);
					storage = [...arr];
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
				return result;
			}
		});

	const formDelete = () =>
		form.deleteItem().then(id => {
			if (isArray(storage)) {
				storage = storage.filter(row => row.id != id);
			}
			dispatch('delete', id);
			return id;
		});

	const formDuplicate = () => {
		const { id, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	const counterpartyNew = () => showModal(counterpartyModalId);
	const counterpartyCreated = async ({ detail: id }) => {
		await fetchGet('/api/private/counterparties', { filters: { roles: { only: ['meeting_participant'] } } }).then(response => {
			$transmitter = { ...$transmitter, counterparties: response.counterparties.sort((a, b) => collator.compare(a.name, b.name)) };
		});

		const el = $transmitter.counterparties.find(e => e.id == id);
		if (isPresent(el)) {
			form.setData({
				counterparties: [
					...form.getData().counterparties,
					{
						value: id,
						label: el.name,
						voting_sum: 0.0,
						unsecured_voting_sum: 0.0,
					},
				],
			});
			form.setItem({
				counterparty_id: id,
				voting_sum: 0.0,
				unsecured_voting_sum: 0.0,
			});
		}
	};
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.readonly || formOptions.method == 'show' || !formCompleted}
	submitButtonAction={formSubmit}
	deleteIconDisabled={formOptions.readonly || formOptions.method == 'new'}
	deleteButtonAction={formDelete}
	deleteConfirmMessage="Вы действительно хотите удалить участника?"
	showCopyAndSaveButton={false}
	showError={false}
	form={true}
	{index}
	size="large"
>
	<h2 slot="header">Карточка участника собрания</h2>
	<div slot="body">
		<Form bind:this={form} bind:isCompleted={formCompleted} {formOptions} on:newCounterparty={counterpartyNew} />
	</div>
</Modal>

<Modal
	modalId={counterpartyModalId}
	submitButtonAction={counterpartySubmit}
	cancelButtonAction={counterpartyCancel}
	deleteButtonAction={counterpartyDelete}
	deleteIconDisabled={true}
	deleteButtonTooltipText={'Вы действительно хотите удалить контрагента?'}
	form={true}
	index={index + 1}
	size="large"
>
	<h2 slot="header" class="counterparty-modal__header">{cardsTitle('контрагента', false)}</h2>
	<div slot="body">
		<CounterpartyForm fixedRoles={['meeting_participant']} on:create={counterpartyCreated} />
	</div>
</Modal>
