<!-- @format -->
<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import { deleteCookie } from 'utils/cookies';
	import Header from '~/svelte/components/header.svelte';
	import { isPresent } from 'utils/tools';

	let groupedProcedures = [];
	let filteredGroupedProcedures = [];
	let showProcedures = location.search.slice(1).split('=')[1] === 'true';
	let collapsed = {};
	let searchTerm;

	const goToNewProcedure = bankruptType => {
		deleteCookie('_rafProcedureId');
		location.href = `/procedures/new?bankrupt_type=${bankruptType}`;
	};

	$: if (searchTerm) {
		filteredGroupedProcedures = [];
		groupedProcedures.forEach(proceduresGroup => {
			if (proceduresGroup.arbitr_manager.text.toLowerCase().includes(searchTerm.toLowerCase())) {
				filteredGroupedProcedures.push(proceduresGroup);
			} else {
				let filteredGroupedProcedure = { ...proceduresGroup, procedures: [] };
				proceduresGroup.procedures.forEach(procedure => {
					if (procedure.text.toLowerCase().includes(searchTerm.toLowerCase())) {
						filteredGroupedProcedure.procedures.push(procedure);
					}
				});
				if (isPresent(filteredGroupedProcedure.procedures)) {
					filteredGroupedProcedures.push(filteredGroupedProcedure);
				}
			}
		});
	} else {
		filteredGroupedProcedures = groupedProcedures;
	}

	onMount(async () => {
		fetchGet('/api/private/procedures').then(response => {
			groupedProcedures = response.grouped_procedures;
			groupedProcedures.forEach(proceduresGroup => (collapsed[proceduresGroup.arbitr_manager.id] = showProcedures));
		});
	});
</script>

<Header><span slot="name">Все АУ и процедуры</span></Header>
<div class="m-b-md overlay">
	<div class="row">
		<input type="text" bind:value={searchTerm} placeholder="Поиск АУ или процедуры" class="search form-control mb-2" />
	</div>
	<div class="row">
		<div class="col-sm-5 col-lg-8 col-xl-5">
			<button
				class="btn btn-sm btn-warning float-left m-r-sm m-b-sm"
				class:disabled={!groupedProcedures.length}
				on:click={groupedProcedures.length && (() => goToNewProcedure('Person'))}
			>
				+ Процедура (ФЛ)
			</button>
			<button
				class="btn btn-sm btn-warning float-left m-r-sm m-b-sm"
				class:disabled={!groupedProcedures.length}
				on:click={groupedProcedures.length && (() => goToNewProcedure('Organization'))}
			>
				+ Процедура (ЮЛ)
			</button>
			<button class="btn btn-sm btn-warning float-left m-r-sm m-b-sm" on:click={() => (location.href = '/arbitr_managers/new')}> + АУ </button>
		</div>
	</div>
	{#each filteredGroupedProcedures as proceduresGroup}
		<h3>
			<div
				class="arbitr_manager-chevron float-left m-r-sm"
				title={collapsed[proceduresGroup.arbitr_manager.id] ? 'скрыть процедуры' : 'показать процедуры'}
				role="button"
				tabindex="0"
				on:click={() => (collapsed[proceduresGroup.arbitr_manager.id] = !collapsed[proceduresGroup.arbitr_manager.id])}
				on:keypress|stopPropagation
			>
				{#if collapsed[proceduresGroup.arbitr_manager.id]}
					<iconify-icon icon="fa-solid:chevron-down" class="m-r-xs hoverable" />
				{:else}
					<iconify-icon icon="fa-solid:chevron-right" class="m-r-sm hoverable" />
				{/if}
			</div>
			<div
				class="arbitr_manager-title float-left m-r-xs"
				title="на страницу АУ"
				role="button"
				tabindex="0"
				on:click={(location.href = `/arbitr_managers/${proceduresGroup.arbitr_manager.id}`)}
				on:keypress|stopPropagation
			>
				{proceduresGroup.arbitr_manager.text}
			</div>
			<div
				class="arbitr_manager-quantity"
				title={collapsed[proceduresGroup.arbitr_manager.id] ? 'скрыть процедуры' : 'показать процедуры'}
				role="button"
				tabindex="0"
				on:click={() => (collapsed[proceduresGroup.arbitr_manager.id] = !collapsed[proceduresGroup.arbitr_manager.id])}
				on:keypress|stopPropagation
			>
				{`(${proceduresGroup.arbitr_manager.quantity})`}
			</div>
		</h3>
		<div class="collapse" class:show={collapsed[proceduresGroup.arbitr_manager.id]} id={`collapse-arbitr-manager-${proceduresGroup.arbitr_manager.id}`}>
			<div class="row">
				<div class="col-sm-5 col-lg-8 col-xl-5">
					<ol>
						{#each [...proceduresGroup.procedures.filter(p => !p.completed), ...proceduresGroup.procedures.filter(p => p.completed)] as procedure}
							<li>
								<span
									class:completed={procedure.completed}
									role="button"
									tabindex="0"
									on:click={() => (location.href = `/procedures/${procedure.id}`)}
									on:keypress|stopPropagation
								>
									{procedure.text}
								</span>
							</li>
						{/each}
					</ol>
				</div>
			</div>
		</div>
	{/each}
</div>

<style>
	ol li {
		margin-bottom: 0.3rem;
		cursor: pointer;
		text-decoration: underline;
	}

	h3 .arbitr_manager-chevron {
		color: #007bff !important;
	}

	button.disabled {
		cursor: inherit;
	}

	.arbitr_manager-title,
	.arbitr_manager-quantity {
		cursor: pointer;
	}

	.completed {
		color: #9a9d9f;
	}
	.search {
		margin-left: 15px;
		max-width: 500px;
	}
</style>
