<!-- @format -->
<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import { bankruptType, transmitter } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import Checkboxes from '~/svelte/components/ui/checkboxes.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';
	import InputDaDataBank from '~/svelte/components/ui/input_dadata_bank.svelte';
	import { hideModal } from '~/svelte/components/modal.svelte';
	import PersonFields from './_person_fields.svelte';
	import OrganiztionFields from './_organization_fields.svelte';
	import Case from 'case';
	import { isObject, isArray, isPresent, isBlank } from 'utils/tools';
	import { counterparty_roles } from './_stores';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import WysiwygTextbox from '~/svelte/components/ui/wysiwyg_textbox.svelte';
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte';

	export let counterpartyId;
	export let selectedCounterpartyId;
	export let fixedTypes = [];
	export let fixedRoles = [];
	export let modalId = 'form-counterparty-modal';
	export let requiredFieldsFilled;
	export let disabled = false;
	export let type = 'counterparty';

	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;

	const collator = new Intl.Collator();
	const dispatch = createEventDispatcher();

	const contractorTypeOptions = [
		{ id: '', text: '' },
		{ id: 'person', text: 'Физическое лицо' },
		{ id: 'organization', text: 'Юридическое лицо' },
		{ id: 'individual_entrepreneur', text: 'ИП' },
	].filter(e => fixedTypes.length == 0 || fixedTypes.includes(e.id));

	const fixedRolesPresent = fixedRoles && fixedRoles.length > 0;

	const nullCounterparty = {
		id: null,
		contractor_type: isArray(fixedTypes) && fixedTypes.length == 1 ? fixedTypes[0] : null,
		contractor_id: null,
		roles: fixedRolesPresent ? fixedRoles : [],
		bank_bik: null,
		bank_name: null,
		bank_cor_account: null,
		bank_account: null,
		bank_rkc: null,
		can_be_destroyed: true,
		additions: {},
		errors: {},
	};

	let individualFields = null;
	let contractor = null;
	let skipVerificationRequired = false;
	let counterparty = { ...nullCounterparty };
	let nameFieldFilled = false;
	//let counterparties

	const bankSelected = ({ detail: rec }) => {
		if (isObject(rec)) {
			counterparty.bank_bik = rec.bic;
			counterparty.bank_rkc = rec.rkc;
			counterparty.bank_cor_account = rec.correspondent_account;
			counterparty.bank_name = rec.name.payment;
		} else {
			counterparty.bank_bik = rec;
		}
	};

	const loadCounterparties = () => {
		let params =
			type == 'worker'
				? { filters: { roles: { only: 'worker' } } }
				: ['debt_restructuring', 'property_realization'].includes(procedurePhase)
					? {}
					: { filters: { roles: { except: ['worker'] } } };

		fetchGet('/api/private/counterparties', params).then(response => {
			$transmitter = { ...$transmitter, counterparties: response.counterparties.sort((a, b) => collator.compare(a.name, b.name)) };
		});
	};

	$: counterparties = $transmitter && $transmitter.counterparties;

	$: method = !!counterpartyId ? fetchPut : fetchPost;

	$: procedure = $transmitter && $transmitter.procedure;
	$: procedurePhase = procedure && procedure.phase;

	$: possibleRoles =
		counterparty.contractor_type == 'person' && type == 'worker'
			? $counterparty_roles.filter(role => !['bank', 'stockbroker', 'cryptoex'].includes(role.id))
			: counterparty.contractor_type == 'person'
				? $counterparty_roles.filter(
						role =>
							!['bank', 'stockbroker', 'cryptoex'].includes(role.id) &&
							!(role.id == 'worker' && !['debt_restructuring', 'property_realization'].includes(procedurePhase)),
					)
				: counterparty.contractor_type == 'individual_entrepreneur'
					? $counterparty_roles.filter(role => !['bank', 'stockbroker', 'worker', 'kdl', 'cryptoex'].includes(role.id))
					: $counterparty_roles.filter(role => !['worker', 'kdl'].includes(role.id));

	$: requiredFieldsFilled = counterparty.contractor_type && counterparty.roles.length && nameFieldFilled; // && !duplicated

	$: if (fixedRoles.some(role => ['bank', 'stockbroker', 'cryptoex'].includes(role))) {
		counterparty = { ...counterparty, contractor_type: 'organization' };
	}

	$: if (fixedRolesPresent) {
		counterparty.roles = [...new Set([...counterparty.roles, ...fixedRoles])];
	}

	$: if ($bankruptType === 'Person') {
		possibleRoles = [...possibleRoles.filter(role => role.id !== 'debtor_participant')];
	}

	$: if (fixedRoles.includes('bank') || fixedRoles.includes('stockbroker')) {
		counterparty.bank_name = null;
	} else {
		counterparty.bank_rkc = null;
	}

	//$: if (counterparty.roles.includes('declarer')) {
	//  counterparty.roles = [...new Set([...counterparty.roles, 'creditor'])]
	//}

	$: data = {
		counterparty: {
			...counterparty,
			contractor_type: ['person', 'individual_entrepreneur'].includes(counterparty.contractor_type) ? 'Person' : Case.capital(counterparty.contractor_type),
		},
		...individualFields,
	};

	$: if (!counterpartyId) {
		counterparty = { ...nullCounterparty };
		contractor = null;
	}

	$: if (counterpartyId && counterpartyId !== counterparty.id) {
		fetchGet(`/api/private/counterparties/${counterpartyId}`).then(response => {
			const contractorType = response.counterparty.contractor_type;
			contractor = { [contractorType]: response.counterparty.contractor };
			counterparty = response.counterparty;
			counterparty.contractor_type = contractor[contractorType].individual_entrepreneur ? 'individual_entrepreneur' : contractorType;
			delete counterparty.contractor;
		});
	}
	$: if (counterparty.roles.includes('kdl')) {
		counterparty.additions.kdl = counterparty.additions.kdl || {};
	} else {
		delete counterparty.additions['kdl'];
	}
	$: if (counterparty.roles.includes('worker')) {
		counterparty.additions.worker = counterparty.additions.worker || {};
		if (!counterparty.additions.worker.salary_arrears) {
			delete counterparty.additions.worker['arrears_before_acceptance'];
			delete counterparty.additions.worker['arrears_after_acceptance'];
		}
	}

	$: activeContractorTypeOptions = fixedRoles.some(role => ['bank', 'stockbroker', 'cryptoex'].includes(role))
		? contractorTypeOptions.filter(option => !['person', 'individual_entrepreneur'].includes(option.id))
		: contractorTypeOptions;

	$: if (activeContractorTypeOptions.filter(o => isPresent(o.id)).length === 1) {
		counterparty.contractor_type = activeContractorTypeOptions.filter(o => isPresent(o.id))[0].id;
	}

	const roleIsFixed = role => fixedRoles.includes(role.id) || (role.id == 'creditor' && isPresent(counterparty.roles.includes('declarer')));

	$: if (counterparty.roles.includes('declarer')) {
		counterparty.roles = [...new Set([...counterparty.roles, 'creditor'])];
	}

	$: roleOptions =
		possibleRoles &&
		possibleRoles.map(role => {
			return roleIsFixed(role) ? { ...role, fixed: true } : role;
		});

	$: formFields = {
		contractorType: {
			disabled: !!counterpartyId || disabled,
			required: true,
			withSearch: false,
			attribute: 'contractor_type',
			model: 'counterparty',
			label: 'Вид контрагента',
			options: activeContractorTypeOptions,
			placeholder: 'Выбрать вид контрагента',
		},
		roles: {
			required: true,
			attribute: 'roles',
			model: 'counterparty',
			label: type == 'worker' ? 'Роль' : 'Роли контрагента',
			options: roleOptions,
			errors: data.counterparty.errors && data.counterparty.errors.roles,
			disabled,
		},
		subsidiaryResponsibilityCheck: {
			label: 'Привлекается к субсидиарной ответственности',
			text: 'да, привлекается',
			disabled,
		},
		submissionClaimsDate: {
			model: 'counterparty',
			label: 'Дата предъявления требований',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			errors: data.counterparty.errors && data.counterparty.errors.filingClaimsDate,
			disabled,
		},
		amountOfClaims: {
			model: 'counterparty',
			attribute: 'amount_of_claims',
			label: 'Сумма требований, руб.',
			placeholder: '0,00',
			type: 'number',
		},
		salaryArrearsCheck: {
			label: 'Есть задолженность по зарплате',
			text: 'да, имеется',
			disabled,
		},
		arrearsBeforeAcceptance: {
			model: 'counterparty',
			attribute: 'arrears_before_acceptance',
			label: 'Сумма задолженности до принятия судом заявления о банкротстве, руб.',
			placeholder: '0,00',
			type: 'number',
		},
		arrearsAfterAcceptance: {
			model: 'counterparty',
			attribute: 'arrears_after_acceptance',
			label: 'Сумма задолженности после принятия судом заявления о банкротстве, руб.',
			placeholder: '0,00',
			type: 'number',
		},
		documentArbitraryText: {
			label: 'Результат',
			disabled,
		},
		bankBik: {
			attribute: 'bank_bik',
			model: 'counterparty',
			label: 'БИК банка',
			// maskOptions: { mask: '000000000' },
			placeholder: 'Введите БИК, наименование или ИНН банка',
			errors: data.counterparty.errors && data.counterparty.errors.bank_bik,
			disabled,
		},
		bankName: {
			attribute: 'bank_name',
			model: 'counterparty',
			label: 'Наименование банка',
			placeholder: 'ПАО “Банк”',
			disabled,
		},
		kdlPosition: {
			attribute: 'position',
			model: 'counterparty',
			label: 'Должность КДЛ',
			placeholder: 'Учредитель',
			disabled,
		},
		bankRkc: {
			attribute: 'bank_rkc',
			model: 'counterparty',
			label: 'РКЦ банка',
			placeholder: 'ГУ Банка России по ЦФО',
			disabled,
		},
		bankCorAccount: {
			attribute: 'bank_cor_account',
			model: 'counterparty',
			label: 'Корреспондентский счёт',
			maskOptions: { mask: '00000000000000000000' },
			placeholder: '12345678901234567890',
			errors: data.counterparty.errors && data.counterparty.errors.bank_cor_account,
			disabled,
		},
		bankAccount: {
			attribute: 'bank_account',
			model: 'counterparty',
			label: 'Расчётный счёт',
			maskOptions: { mask: '00000000000000000000' },
			placeholder: '12345678901234567890',
			errors: data.counterparty.errors && data.counterparty.errors.bank_account,
			disabled,
		},
		dismissalOrderDate: {
			attribute: 'dismissal_order_date',
			model: 'counterparty',
			label: 'Дата приказа об увольнении',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			errors: data.counterparty.errors && data.counterparty.errors.dismissal_order_date,
			disabled,
		},
	};

	handleSubmit = () => {
		let noErrorMarks = [];

		for (let model in data) {
			for (let attribute in data[model]) {
				if (['errors', 'reg_address_full'].includes(attribute)) {
					continue;
				}

				const errors = validate(model, attribute, data[model][attribute], skipVerificationRequired);
				noErrorMarks.push(!errors);

				if (errors) {
					data[model]['errors'] = data[model]['errors'] ? { ...data[model]['errors'], [attribute]: errors } : { [attribute]: errors };
				} else {
					if (data[model]['errors']) {
						delete data[model]['errors'][attribute];
					}
				}
			}
		}

		if (noErrorMarks.every(mark => mark)) {
			let params = data;
			for (let model in params) {
				delete params[model]['errors'];
			}

			return method(`/api/private/counterparties${counterpartyId ? `/${counterpartyId}` : ''}`, data).then(response => {
				dispatch(counterpartyId ? 'update' : 'create', response.counterparty_id || counterpartyId);
				hideModal(modalId);
			});
			//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))
		} else {
			return false;
		}
	};

	handleCancel = () => {
		counterparty = { ...nullCounterparty };
		selectedCounterpartyId = null;
		// $transmitter = {...$transmitter, counterparties: null}
		fixedRoles = [];
	};

	handleDelete = () => {
		return fetchDelete(`/api/private/counterparties/${counterpartyId}`).then(
			_response => ($transmitter.counterparties = $transmitter.counterparties.filter(с => с.id != counterpartyId)),
		);
		//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))
	};

	onMount(() => {
		if ($transmitter && isBlank($transmitter.counterparties)) {
			loadCounterparties();
		}
	});
</script>

{#if type !== 'worker'}
	<Select2 {...formFields.contractorType} bind:value={counterparty.contractor_type} />
{/if}
{#if counterparty.contractor_type}
	<Checkboxes {...formFields.roles} bind:value={counterparty.roles} />
{/if}
{#if ['person', 'individual_entrepreneur'].includes(counterparty.contractor_type)}
	<PersonFields
		{contractor}
		{counterparty}
		{disabled}
		counterparties={counterparties && counterparties.filter(c => c.contractor_type === 'Person')}
		errors={data.person && data.person.errors}
		bind:individualFields
		bind:nameFieldFilled
	/>
{:else if counterparty.contractor_type === 'organization'}
	<OrganiztionFields
		{contractor}
		{disabled}
		counterparties={counterparties && counterparties.filter(c => c.contractor_type === 'Organization')}
		errors={data.organization && data.organization.errors}
		bind:counterparty
		bind:individualFields
		bind:nameFieldFilled
	/>
{/if}
{#if counterparty.contractor_type}
	<InputDaDataBank {...formFields.bankBik} bind:value={counterparty.bank_bik} on:change={bankSelected} />
	{#if counterparty.roles.includes('bank')}
		<Input {...formFields.bankRkc} bind:value={counterparty.bank_rkc} />
		<Input {...formFields.bankCorAccount} bind:value={counterparty.bank_cor_account} />
	{:else}
		<Input {...formFields.bankName} bind:value={counterparty.bank_name} />
		<Input {...formFields.bankCorAccount} bind:value={counterparty.bank_cor_account} />
		<Input {...formFields.bankAccount} bind:value={counterparty.bank_account} />
	{/if}
{/if}

{#if counterparty.roles.includes('worker')}
	<Checkbox {...formFields.salaryArrearsCheck} bind:checked={counterparty.additions.worker.salary_arrears} />
	{#if counterparty.additions.worker.salary_arrears}
		<InputCurrency {...formFields.arrearsBeforeAcceptance} bind:value={counterparty.additions.worker.arrears_before_acceptance} />
		<InputCurrency {...formFields.arrearsAfterAcceptance} bind:value={counterparty.additions.worker.arrears_after_acceptance} />
	{/if}
	<Datepicker {...formFields.dismissalOrderDate} bind:value={counterparty.additions.worker.dismissal_order_date} />
{/if}
{#if counterparty.contractor_type === 'person' && counterparty.roles.includes('kdl')}
	<Input {...formFields.kdlPosition} bind:value={counterparty.additions.kdl.position} />
	<Checkbox {...formFields.subsidiaryResponsibilityCheck} bind:checked={counterparty.additions.kdl.is_subsidiary_responsibility} />
	{#if counterparty.additions.kdl.is_subsidiary_responsibility}
		<Datepicker {...formFields.submissionClaimsDate} bind:value={counterparty.additions.kdl.submission_claims_date} />
		<InputCurrency {...formFields.amountOfClaims} bind:value={counterparty.additions.kdl.amount_of_claims} />
		<WysiwygTextbox {...formFields.documentArbitraryText} bind:value={counterparty.additions.kdl.result} />
	{/if}
{/if}
