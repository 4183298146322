/** @format */

import { readable, derived } from 'svelte/store';

export const counterparty_roles = readable([
	{ id: 'declarer', text: 'Заявитель' },
	{ id: 'creditor', text: 'Кредитор' },
	{ id: 'current_creditor', text: 'Текущий кредитор' },
	{ id: 'bank', text: 'Банк' },
	{ id: 'stockbroker', text: 'Брокер' },
	{ id: 'payee', text: 'Получатель платежа' },
	{ id: 'debtor', text: 'Дебитор' },
	{ id: 'debtor_participant', text: 'Участник должника (на дату введения процедуры)' },
	{ id: 'meeting_participant', text: 'Участник собрания' },
	{ id: 'worker', text: 'Работник' },
	{ id: 'kdl', text: 'Контролирующее должника лицо' },
	{ id: 'cryptoex', text: 'Криптобиржа' },
]);

export const counterparty_roles_map = derived(counterparty_roles, $counterparty_roles => {
	const h = {};
	for (let i = 0, len = $counterparty_roles.length; i < len; i++) {
		h[$counterparty_roles[i].id] = $counterparty_roles[i].text;
	}
	return h;
});
