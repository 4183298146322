<!-- @format -->
<script context="module">
	let handleSubmit;
	let handleCancel;
	let handleDelete;

	export function workerSubmit() {
		return handleSubmit();
	}
	export function workerCancel() {
		return handleCancel();
	}
	export function workerDelete() {
		return handleDelete();
	}
</script>

<script>
	import CounterpartyForm from '~/svelte/counterparties/_form.svelte';

	export let workerId;
	export let selectedWorkerId;
	export let fixedTypes = [];
	export let fixedRoles = [];
	export let modalId = 'form-worker-modal';
	export let requiredFieldsFilled;
	export let disabled = false;
	export let type = 'worker';
</script>

<CounterpartyForm
	{disabled}
	{type}
	{fixedTypes}
	{fixedRoles}
	{modalId}
	bind:selectedCounterpartyId={selectedWorkerId}
	bind:counterpartyId={workerId}
	bind:handleSubmit
	bind:handleDelete
	bind:handleCancel
	bind:requiredFieldsFilled
	on:create
/>
