<!-- @format -->
<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, transmitter, bankruptIsPerson, bankruptIsOrganization } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { fetchGet } from 'utils/fetch';
	import Header from '../components/header.svelte';
	import { showModal } from '../components/modal.svelte';
	import CounterpartyModal from './_modal.svelte';
	import SvelteTooltip from 'svelte-tooltip';
	import { counterparty_roles_map } from './_stores';
	import { isPresent } from 'utils/tools';

	const collator = new Intl.Collator();

	let procedureId = null;
	let procedurePhase = null;
	let counterpartyId = null;
	let requiredFieldsFilled = false;
	let mounted = false;

	const tableTitles = ['Наименование или ФИО', 'Роли контрагента', 'ИНН', 'Почтовый адрес'];

	$: counterparties = ($transmitter && $transmitter.counterparties) || counterparties || [];
	$: counterparty = counterparties && counterparties.find(counterparty => counterparty.id === counterpartyId);
	$: deleteIconDisabled = !counterpartyId || (counterparty && !counterparty.can_be_destroyed);

	const openModalForm = id => {
		counterpartyId = id;
		showModal('form-counterparty-modal');
	};

	const getCounterparties = () => {
		mounted = false;
		const params = ['debt_restructuring', 'property_realization'].includes(procedurePhase) ? {} : { filters: { roles: { except: ['worker'] } } };

		fetchGet('/api/private/counterparties', params)
			.then(response => ($transmitter = { ...$transmitter, counterparties: response.counterparties.sort((a, b) => collator.compare(a.name, b.name)) }))
			.then(() => (mounted = true));
	};

	onMount(() => {
		procedureId = $procedure && $procedure.id;
		procedurePhase = $procedure && $procedure.phase;
		getCounterparties();
	});
</script>

<Header><span slot="name">Контрагенты</span></Header>
<div class="m-b-lg mb-0">
	<button
		class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!$procedureAvailable || !procedureId}
		on:click={procedureId && $procedureAvailable && openModalForm(null)}
	>
		+ Контрагент
	</button>
</div>
<div class="m-b-lg overlay p-1 footer-margin">
	<table class="table table-hover m-0 table-mobile">
		<thead class="thead-light">
			<tr>
				{#each tableTitles as title}
					<th class="text-center p-w-xs">
						{title}
					</th>
				{/each}
			</tr>
		</thead>
		<tbody>
			{#if mounted}
				{#if isPresent(counterparties)}
					{#each counterparties as counterparty}
						<tr on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && (() => openModalForm(counterparty.id))}>
							<td>
								{#if !counterparty.can_be_destroyed}
									<SvelteTooltip tip="Невозможно удалить запись" right>{counterparty.name || ''}</SvelteTooltip>
								{:else}
									{counterparty.name || ''}
								{/if}
							</td>
							<td>{counterparty.roles ? counterparty.roles.map(role => $counterparty_roles_map[role]).join(', ') : ''}</td>
							<td>{counterparty.inn || ''}</td>
							<td>{counterparty.mailing_address_full || ''}</td>
						</tr>
					{/each}
				{:else}
					<tr><td class="text-center" colspan="4">Нет данных</td></tr>
				{/if}
			{:else}
				<tr><td class="text-center" colspan="4"><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
			{/if}
		</tbody>
	</table>
</div>
<CounterpartyModal {counterpartyId} {deleteIconDisabled} bind:requiredFieldsFilled on:create={getCounterparties} on:update={getCounterparties} />

<style>
	table,
	tr {
		border-collapse: separate;
		cursor: pointer;
	}
</style>
